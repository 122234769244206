import { DataInitializerBasic } from '../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../core/submodule/core-submodule.interfaces';

export type TInfraPrelimGroupIdentifiers = 'groupHardware' | 'groupNetworking';

export type TInfraPrelimDataKeys =
  | 'ownsHardware'
  | 'usingWindows10'
  | 'hasCameras'
  | 'hasMics'
  | 'hasSingleInternetType'
  | 'singleInternetType'
  | 'multiInternetType'
  | 'internetSpeed'
  | 'hasSecondaryInternet'
  | 'secondaryInternetSpeed'
  | 'hasAutoFailover'
  | 'hasWiFi'
  | 'hasSeparateCustomerWiFi';

const infraPrelimGroupMap = {
  moduleType: 'infrastructure',
  submoduleType: 'preliminary',
  discriminator: 'infrastructure-preliminary',
  groups: {
    groupHardware: ['ownsHardware', 'usingWindows10', 'hasCameras', 'hasMics'],
    groupNetworking: [
      'hasSingleInternetType',
      'singleInternetType',
      'multiInternetType',
      'internetSpeed',
      'hasSecondaryInternet',
      'secondaryInternetSpeed',
      'hasAutoFailover',
      'hasWiFi',
      'hasSeparateCustomerWiFi',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TInfraPrelimGroupIdentifiers,
  TInfraPrelimDataKeys
>;

export type TInfraPrelimGroupMap = typeof infraPrelimGroupMap;

export class InfraPreliminaryData
  implements TDataDefinition<TInfraPrelimDataKeys>
{
  // groupHardware
  ownsHardware = new DataInitializerBasic<boolean>();
  usingWindows10 = new DataInitializerBasic<boolean>();
  hasCameras = new DataInitializerBasic<boolean>();
  hasMics = new DataInitializerBasic<boolean>();

  // groupNetworking
  hasSingleInternetType = new DataInitializerBasic<boolean>();
  singleInternetType = new DataInitializerBasic<string>();
  multiInternetType = new DataInitializerBasic<string>();
  internetSpeed = new DataInitializerBasic<string>();
  hasSecondaryInternet = new DataInitializerBasic<boolean>();
  secondaryInternetSpeed = new DataInitializerBasic<string>();
  hasAutoFailover = new DataInitializerBasic<boolean>();
  hasWiFi = new DataInitializerBasic<boolean>();
  hasSeparateCustomerWiFi = new DataInitializerBasic<boolean>();
}

export const infraPreliminaryStructure = {
  moduleType: 'infrastructure',
  submoduleType: 'preliminary',
  discriminator: 'infrastructure-preliminary',
  groups: {
    groupHardware: {
      groupLabel: 'Hardware',
      groupType: 'standard',
      groupUrl: 'groupHardware',
      questions: {
        ownsHardware: {
          questionKey: 'ownsHardware',
          label: 'Owns Hardware',
          prompt: [
            {
              attributes: null,
              text: 'Do you own your hardware (e.g., servers, printers, etc.) or do you lease it from your retiring DMS?',
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            { label: 'Own', value: true },
            { label: 'Lease', value: false },
          ],
          validation: [],
          dependencies: [],
        },
        usingWindows10: {
          questionKey: 'usingWindows10',
          label: 'Using Windows 10',
          prompt: [
            {
              attributes: null,
              text: 'Are all PCs in the dealership running Windows 10?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Your PCs must be running an up-to-date version of Windows 10 to support VUE. This excludes Windows emulators/virtual machines on Mac OS.',
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [],
        },
        hasCameras: {
          questionKey: 'hasCameras',
          label: 'Has Cameras',
          prompt: [
            {
              attributes: null,
              text: "Do your managers' PCs have cameras?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'During training, it is beneficial for managers to have cameras to join video calls.',
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [],
        },
        hasMics: {
          questionKey: 'hasMics',
          label: 'Has Mics',
          prompt: [
            {
              attributes: null,
              text: "Do your managers' PCs have microphones?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'During training, it is beneficial for managers to have microphones to join video calls.',
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupNetworking: {
      groupLabel: 'Networking',
      groupType: 'standard',
      groupUrl: 'groupNetworking',
      questions: {
        hasSingleInternetType: {
          questionKey: 'hasSingleInternetType',
          label: 'Has Single Internet Type',
          prompt: [
            {
              attributes: null,
              text: 'Does the entire dealership use the same type of Internet connection (e.g., fiber, cable, etc.)?',
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['singleInternetType'],
            },
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['multiInternetType'],
            },
          ],
        },
        singleInternetType: {
          questionKey: 'singleInternetType',
          label: 'Single Internet Type',
          prompt: [
            {
              attributes: null,
              text: 'What type of Internet connection does the dealership use (e.g., fiber, cable, etc.)?',
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        multiInternetType: {
          questionKey: 'multiInternetType',
          label: 'Multi Internet Type',
          prompt: [
            {
              attributes: null,
              text: 'What types of Internet connections does the dealership use (e.g., fiber, cable, etc.)?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Please explain what type of Internet connection each department uses.',
            },
          ],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        internetSpeed: {
          questionKey: 'internetSpeed',
          label: 'Internet Speed',
          prompt: [
            {
              attributes: null,
              text: "What is the upload speed and download speed of the dealership's Internet connection?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'You can test your Internet speed by going to ',
            },
            {
              attributes: {
                link: 'https://www.speedtest.net/',
              },
              text: 'https://www.speedtest.net/',
            },
            {
              attributes: null,
              text: '. Please test each department during peak hours.',
            },
          ],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        hasSecondaryInternet: {
          questionKey: 'hasSecondaryInternet',
          label: 'Has Secondary Internet',
          prompt: [
            {
              attributes: null,
              text: 'Does the dealership have a secondary Internet connection?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'A secondary Internet connection is not required but is recommended.',
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['secondaryInternetSpeed', 'hasAutoFailover'],
            },
          ],
        },
        secondaryInternetSpeed: {
          questionKey: 'secondaryInternetSpeed',
          label: 'Secondary Internet Speed',
          prompt: [
            {
              attributes: null,
              text: "What is the upload speed and download speed of the dealership's secondary Internet connection?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'You can test your Internet speed by going to ',
            },
            {
              attributes: {
                link: 'https://www.speedtest.net/',
              },
              text: 'https://www.speedtest.net/',
            },
          ],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        hasAutoFailover: {
          questionKey: 'hasAutoFailover',
          label: 'Has Auto Failover',
          prompt: [
            {
              attributes: null,
              text: 'Does the dealership router automatically failover to the secondary Internet connection if the primary connection goes down?',
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [],
        },
        hasWiFi: {
          questionKey: 'hasWiFi',
          label: 'Has WiFi',
          prompt: [
            {
              attributes: null,
              text: 'Does the dealership have WiFi?',
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['hasSeparateCustomerWiFi'],
            },
          ],
        },
        hasSeparateCustomerWiFi: {
          questionKey: 'hasSeparateCustomerWiFi',
          label: 'Has Separate Customer WiFi',
          prompt: [
            {
              attributes: null,
              text: 'Does the dealership have a separate WiFi network for customers?',
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['infrastructure-preliminary'];

import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import {
  PayrollPreliminaryData,
  payrollPreliminaryStructure,
} from './payroll-prelim-data.interfaces';

export class PayrollPreliminarySubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'payroll-preliminary'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'payroll' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'payroll-preliminary' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        group401k: {
          total: 0,
          complete: 0,
        },
        groupACH: {
          total: 0,
          complete: 0,
        },
        groupPayCycle: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: PayrollPreliminaryData;
  override structure = payrollPreliminaryStructure['groups'];
  constructor(initial?: ICoreSubmodule<'payroll-preliminary'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new PayrollPreliminaryData();
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CoreSubmoduleVisual, CoreQuestionVisual } from '@dominion/interfaces';
import { PromptComponent } from '../../../prompt/prompt.component';
import { UrlService } from '../../../services/url.service';

@Component({
  selector: 'dominion-question-list',
  standalone: true,
  imports: [CommonModule, PromptComponent],
  templateUrl: './question-list.component.html',
  styleUrl: './question-list.component.css',
})
export class QuestionListComponent {
  @Input() isDependency = false;
  @Input() expandedQuestionKey = '';
  @Input() groups: {
    preliminary: CoreSubmoduleVisual['groups'];
    maintenance: CoreSubmoduleVisual['groups'];
  };
  @Input() questions?: CoreQuestionVisual[] = [];
  @Input() selectedModule = '';
  @Input() selectedSubmodule = '';
  @Input() selectedGroupKey = '';
  @Input() selectedQuestionKey = '';
  @Input() selectedQuestion?: CoreQuestionVisual;

  copiedQuestionKey = '';

  constructor(private urlService: UrlService) {}

  copyQuestionLink(event: MouseEvent, questionKey: string) {
    event.stopPropagation();

    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;
    params.set(
      'question',
      this.isDependency ? this.selectedQuestionKey : questionKey,
    );
    currentUrl.hash = questionKey;

    navigator.clipboard.writeText(currentUrl.href).catch((err) => {
      console.error('Failed to copy: ', err);
    });

    this.copiedQuestionKey = questionKey;
    setTimeout(() => {
      this.copiedQuestionKey = '';
    }, 2000);
  }

  optionLabelForValue(question: CoreQuestionVisual, value: unknown) {
    return (
      question.options.find((option) => option.value === value)?.label ?? ''
    );
  }

  countOptionDependencies(
    optionValue: unknown,
    dependencies: CoreQuestionVisual['dependencies'],
  ) {
    return dependencies.reduce(
      (acc, dependency) =>
        acc +
        (dependency.value === optionValue ? dependency.questions.length : 0),
      0,
    );
  }

  countTotalDependencies(dependencies: CoreQuestionVisual['dependencies']) {
    return dependencies.reduce(
      (acc, dependency) => acc + dependency.questions.length,
      0,
    );
  }

  expandQuestion(questionKey: string, isDependency = false) {
    const key = isDependency
      ? questionKey
      : this.selectedQuestionKey === questionKey
        ? ''
        : questionKey;

    this.urlService.setQueryParams(
      {
        module: this.selectedModule,
        submodule: this.selectedSubmodule,
        group: this.selectedGroupKey,
        question: isDependency ? this.selectedQuestionKey : key,
      },
      key,
    );
  }
}

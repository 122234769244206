import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  AccountingDefaultData,
  accountingDefaultStructure,
} from './accounting-default-data.interfaces';

export class AccountingDefaultSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'accounting-default'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'accounting' as const;
  submoduleType = 'default' as const;
  discriminator = 'accounting-default' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupAccountingDefaultGeneral: {
          total: 0,
          complete: 0,
        },
        groupAccountingDefaultFinancial: {
          total: 0,
          complete: 0,
        },
        groupAccountingDefaultDealPosting: {
          total: 0,
          complete: 0,
        },
        groupAccountingDefaultAccountsReceivable: {
          total: 0,
          complete: 0,
        },
        groupAccountingDefaultAccountsPayable: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: AccountingDefaultData;
  override structure = accountingDefaultStructure['groups'];
  constructor(initial?: ICoreSubmodule<'accounting-default'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      this.data = new AccountingDefaultData();
    }
  }
}

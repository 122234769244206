import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TPartsMaintenanceGroupIdentifiers =
  | 'groupPartsDisclaimer'
  | 'groupPartsDiscounts'
  | 'groupPartsFees'
  | 'groupPartsInventory'
  | 'groupPartsInvoices'
  | 'groupPartsOrderSources'
  | 'groupPartsOrderVendors'
  | 'groupPartsPriceCodeMatrix'
  | 'groupPartsPriceCodes';

export type TPartsMaintenanceDataKeys =
  | 'partsDisclaimer'
  | 'useDefaultPartsDisclaimer'
  | 'partsDiscounts'
  | 'partsFees'
  | 'partsInventory'
  | 'partsInvoicesCusPay'
  | 'partsInvoicesIntPay'
  | 'partsInvoicesWhlPay'
  | 'partsOrderSources'
  | 'partsOrderVendors'
  | 'usesPriceCodeMatrices'
  | 'partsPriceCodeMatrix'
  | 'partsPriceCodes';

const partsMaintenanceGroupMap = {
  moduleType: 'parts',
  submoduleType: 'maintenance',
  discriminator: 'parts-maintenance',
  groups: {
    groupPartsDisclaimer: ['useDefaultPartsDisclaimer', 'partsDisclaimer'],
    groupPartsDiscounts: ['partsDiscounts'],
    groupPartsFees: ['partsFees'],
    groupPartsInventory: ['partsInventory'],
    groupPartsInvoices: [
      'partsInvoicesCusPay',
      'partsInvoicesIntPay',
      'partsInvoicesWhlPay',
    ],
    groupPartsOrderSources: ['partsOrderSources'],
    groupPartsOrderVendors: ['partsOrderVendors'],
    groupPartsPriceCodeMatrix: [
      'partsPriceCodeMatrix',
      'usesPriceCodeMatrices',
    ],
    groupPartsPriceCodes: ['partsPriceCodes'],
  },
} satisfies TSubmoduleGroupMap<
  TPartsMaintenanceGroupIdentifiers,
  TPartsMaintenanceDataKeys
>;

export type TPartsMaintenanceGroupMap = typeof partsMaintenanceGroupMap;

export class PartsMaintenanceData
  implements TDataDefinition<TPartsMaintenanceDataKeys>
{
  partsDisclaimer = new DataInitializerBasic<IFileUploadReference[]>([]);
  useDefaultPartsDisclaimer = new DataInitializerBasic<boolean>();
  partsDiscounts = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsFees = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsInventory = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsInvoicesCusPay = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsInvoicesIntPay = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsInvoicesWhlPay = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsOrderSources = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsOrderVendors = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsPriceCodeMatrix = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsPriceCodes = new DataInitializerBasic<IFileUploadReference[]>([]);
  usesPriceCodeMatrices = new DataInitializerBasic<boolean>();
}

export const partsMaintenanceStructure: Structures['parts-maintenance'] = {
  moduleType: 'parts',
  submoduleType: 'maintenance',
  discriminator: 'parts-maintenance',
  groups: {
    groupPartsDisclaimer: {
      groupLabel: 'Invoice Disclaimer',
      groupUrl: 'groupPartsDisclaimer',
      groupType: 'standard',
      questions: {
        useDefaultPartsDisclaimer: {
          questionKey: 'useDefaultPartsDisclaimer',
          label: 'Use Default Parts Invoice Disclaimer',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Do you want to use VUE's default parts invoice disclaimer?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: "VUE's default parts invoice disclaimer is:\n\nAny warranties on the products sold hereby are those made by the manufacturer. The seller hereby expressly disclaims all warranties, either expressed or implied, including any warranty of merchantability or fitness for a particular purpose, and neither assumes nor authorizes any other person to assume for it any liability in connection with the said sale of said products.\n    \u2022 ALL RETURNS MUST BE MADE WITHIN 10 DAYS AND\n       SUBJECT TO 20% HANDLING CHARGE.\n    \u2022 NO REFUNDS ON ELECTRICAL PARTS OR SPECIAL\n       ORDER PART.\n    \u2022 ALL RETURNED PARTS MUST BE IN ORIGINAL\n       SALEABLE PACKAGING.",
            },
          ],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['partsDisclaimer'],
            },
          ],
        },
        partsDisclaimer: {
          questionKey: 'partsDisclaimer',
          label: 'Parts Invoice Disclaimer',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide the disclaimer that should print on your parts invoices.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsDiscounts: {
      groupLabel: 'Common Discounts',
      groupUrl: 'groupPartsDiscounts',
      groupType: 'standard',
      questions: {
        partsDiscounts: {
          questionKey: 'partsDiscounts',
          label: 'Common Discounts',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of some of the most common discounts that you apply to your parts invoices.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each discount, please provide:\n    \u2022  the code\n    \u2022  name/description\n    \u2022  whether the discount is shared with the service department',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsFees: {
      groupLabel: 'Common Fees',
      groupUrl: 'groupPartsFees',
      groupType: 'standard',
      questions: {
        partsFees: {
          questionKey: 'partsFees',
          label: 'Common Fees',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of some of the most common fees that you apply to your parts invoices.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each fee, please provide:\n    \u2022 the code\n    \u2022 name/description\n    \u2022 whether the fee is shared with service',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsInventory: {
      groupLabel: 'Current Inventory',
      groupUrl: 'groupPartsInventory',
      groupType: 'standard',
      questions: {
        partsInventory: {
          questionKey: 'partsInventory',
          label: 'Current Inventory',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a parts inventory report from your retiring DMS with as much detail as possible.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsInvoices: {
      groupLabel: 'Invoice Examples',
      groupUrl: 'groupPartsInvoices',
      groupType: 'standard',
      questions: {
        partsInvoicesCusPay: {
          questionKey: 'partsInvoicesCusPay',
          label: 'Customer Pay Examples',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 separate examples of parts invoices that use ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each invoice, please include:\n    \u2022 the customer copy\n    \u2022 the accounting copy\n    \u2022 taxes/fees/discounts',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        partsInvoicesIntPay: {
          questionKey: 'partsInvoicesIntPay',
          label: 'Internal Pay Examples',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 separate examples of parts invoices that use ',
            },
            {
              attributes: { bold: true },
              text: 'internal pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each invoice, please include:\n    \u2022 the customer copy\n    \u2022 the accounting copy\n    \u2022 taxes/fees/discounts',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        partsInvoicesWhlPay: {
          questionKey: 'partsInvoicesWhlPay',
          label: 'Wholesale Pay Examples',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 separate examples of parts invoices that use ',
            },
            {
              attributes: { bold: true },
              text: 'wholesale pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each invoice, please include:\n    \u2022 the customer copy\n    \u2022 the accounting copy\n    \u2022 taxes/fees/discounts',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsOrderSources: {
      groupLabel: 'Order Sources',
      groupUrl: 'groupPartsOrderSources',
      groupType: 'standard',
      questions: {
        partsOrderSources: {
          questionKey: 'partsOrderSources',
          label: 'Order Sources',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your parts order sources.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This list is used to ensure that your data is properly converted from your retiring DMS to VUE.\n\nFor each order source, please provide:\n',
            },
            {
              attributes: null,
              text: '    \u2022 the source name\n    \u2022 manufacturer\n    \u2022 price code\n    \u2022 description\n    \u2022 OEM class code\n    \u2022 part type\n    \u2022 minimum days supply\n    \u2022 maximum days supply\n    \u2022 rounding factor\n    \u2022 search period\n    \u2022 include lost sales\n    \u2022 phase in demand\n    \u2022 phase in months\n    \u2022 phase in hits\n    \u2022 phase out demand\n    \u2022 phase out months',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsOrderVendors: {
      groupLabel: 'Order Vendors',
      groupUrl: 'groupPartsOrderVendors',
      groupType: 'standard',
      questions: {
        partsOrderVendors: {
          questionKey: 'partsOrderVendors',
          label: 'Order Vendors',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of any vendor, including your OEMs, that you currently order parts from.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each vendor, please provide:\n    \u2022 code\n    \u2022 name\n    \u2022 address\n    \u2022 phone number',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsPriceCodeMatrix: {
      groupLabel: 'Price Code Matrix',
      groupUrl: 'groupPartsPriceCodeMatrix',
      groupType: 'standard',
      questions: {
        usesPriceCodeMatrices: {
          questionKey: 'usesPriceCodeMatrices',
          label: 'Use Price Code Matrices',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you use price code matrices?',
            },
          ],
          subprompt: [],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['partsPriceCodeMatrix'],
            },
          ],
        },
        partsPriceCodeMatrix: {
          questionKey: 'partsPriceCodeMatrix',
          label: 'Price Code Matrix',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide your parts price code matrices.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each matrix, please provide:\n    \u2022 the matrix name\n    \u2022 the price code, description, pricing source (cost, list),\n       and the pricing table (e.g., $0.01-$5.00, 100%)',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsPriceCodes: {
      groupLabel: 'Price Codes',
      groupUrl: 'groupPartsPriceCodes',
      groupType: 'standard',
      questions: {
        partsPriceCodes: {
          questionKey: 'partsPriceCodes',
          label: 'Price Codes',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your parts price codes.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Parts are priced based on the default price code assigned to the Account Code (AC) when the part is billed out. The price code can be automatically overridden based on system setups. In addition, the price code can also be manually overridden by users with proper permission.\n\nFor each price code, please provide:\n    \u2022 code\n    \u2022 name/description\n    \u2022 sale source (cost, list, wholesale, matrix)\n    \u2022 show source\n    \u2022 sale percent (markup %)\n    \u2022 show percent',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
};

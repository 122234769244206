import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  PartsMaintenanceData,
  partsMaintenanceStructure,
} from './parts-maintenance-data.interfaces';

export class PartsMaintenanceSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'parts-maintenance'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'parts' as const;
  submoduleType = 'maintenance' as const;
  discriminator = 'parts-maintenance' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupPartsDisclaimer: {
          total: 0,
          complete: 0,
        },
        groupPartsDiscounts: {
          total: 0,
          complete: 0,
        },
        groupPartsFees: {
          total: 0,
          complete: 0,
        },
        groupPartsInventory: {
          total: 0,
          complete: 0,
        },
        groupPartsInvoices: {
          total: 0,
          complete: 0,
        },
        groupPartsOrderSources: {
          total: 0,
          complete: 0,
        },
        groupPartsOrderVendors: {
          total: 0,
          complete: 0,
        },
        groupPartsPriceCodeMatrix: {
          total: 0,
          complete: 0,
        },
        groupPartsPriceCodes: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: PartsMaintenanceData;
  override structure = partsMaintenanceStructure['groups'];
  constructor(initial?: ICoreSubmodule<'parts-maintenance'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      this.data = new PartsMaintenanceData();
    }
  }
}

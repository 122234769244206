import {
  DataInitializerBasic,
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core';

export type TPayrollDefaultGroupIdentifiers = 'groupPayrollDefaultGeneral';

export type TPayrollDefaultDataKeys =
  | 'useHireology'
  | 'hireologyEmployeeId'
  | 'hireologyPayType';

const payrollDefaultGroupMap = {
  moduleType: 'payroll',
  submoduleType: 'default',
  discriminator: 'payroll-default',
  groups: {
    groupPayrollDefaultGeneral: [
      'useHireology',
      'hireologyEmployeeId',
      'hireologyPayType',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TPayrollDefaultGroupIdentifiers,
  TPayrollDefaultDataKeys
>;

export type TPayrollDefaultGroupMap = typeof payrollDefaultGroupMap;

export class PayrollDefaultData
  implements TDataDefinition<TPayrollDefaultDataKeys>
{
  useHireology = new DataInitializerBasic<boolean>();
  hireologyEmployeeId = new DataInitializerBasic<
    'payroll-employee-id' | 'ssn'
  >();
  hireologyPayType = new DataInitializerBasic<'regular-hours' | 'total-cost'>();
}

export const payrollDefaultStructure = {
  moduleType: 'payroll',
  submoduleType: 'default',
  discriminator: 'payroll-default',
  groups: {
    groupPayrollDefaultGeneral: {
      groupLabel: 'General',
      groupType: 'standard',
      groupUrl: 'groupPayrollDefaultGeneral',
      questions: {
        useHireology: {
          questionKey: 'useHireology',
          label: 'Use Hireology',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you plan to use Hireology/Netchex for payroll?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['hireologyEmployeeId', 'hireologyPayType'],
            },
          ],
        },
        hireologyEmployeeId: {
          questionKey: 'hireologyEmployeeId',
          label: 'Hireology Employee ID',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'How will you identify employees in Hireology/Netchex?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'payroll-employee-id',
              label: 'Employee ID',
            },
            {
              value: 'ssn',
              label: 'SSN',
            },
          ],
          validation: [],
          dependencies: [],
        },
        hireologyPayType: {
          questionKey: 'hireologyPayType',
          label: 'Hireology Pay Type',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'What pay type will you use in Hireology/Netchex?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'regular-hours',
              label: 'Regular Hours',
            },
            {
              value: 'total-cost',
              label: 'Total Cost',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['payroll-default'];

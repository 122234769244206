import { SubmoduleType } from '../modules/core/submodule/core-submodule.interfaces';

export function submoduleTypeToPhaseLabel(
  submoduleType: SubmoduleType,
): string {
  switch (submoduleType) {
    case 'preliminary':
      return 'Phase 1';
    case 'maintenance':
      return 'Phase 2';
    case 'default':
      return 'Phase 3';
    default:
      return 'Unknown Phase';
  }
}

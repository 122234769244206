import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import {
  FIPreliminaryData,
  fiPreliminaryStructure,
} from './fi-prelim-data.interfaces';

export class FIPreliminarySubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'fi-preliminary'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'fi' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'fi-preliminary' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupCreditLife: {
          total: 0,
          complete: 0,
        },
        groupLeasing: {
          total: 0,
          complete: 0,
        },
        groupMisc: {
          total: 0,
          complete: 0,
        },
        groupPrinters: {
          total: 0,
          complete: 0,
        },
        groupRates: {
          total: 0,
          complete: 0,
        },
        groupVendors: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: FIPreliminaryData;
  override structure = fiPreliminaryStructure['groups'];
  constructor(initial?: ICoreSubmodule<'fi-preliminary'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new FIPreliminaryData();
    }
  }
}

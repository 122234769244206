<div class="grid h-full grid-rows-[auto_1fr] gap-8 p-4 pb-0 antialiased">
  <div class="flex flex-col gap-8">
    <h2 dominionCoreOutletTitle>Questions</h2>
    <div class="flex flex-wrap items-center justify-between gap-4">
      <ul role="list" class="flex items-center gap-px">
        @for (module of moduleTypes; track module) {
          <li class="overflow-hidden first:rounded-l-sm last:rounded-r-sm">
            <button
              (click)="selectModule(module)"
              [attr.aria-selected]="module === selectedModule()"
              class="aria-selected:bg-dms-teal flex w-full shrink-0 cursor-pointer items-center justify-center bg-gray-100 px-2 py-1 text-sm font-medium aria-selected:text-white"
            >
              {{ module | moduleType }}
            </button>
          </li>
        }
      </ul>
      <dominion-question-search
        [questions]="searchableQuestions"
      ></dominion-question-search>
    </div>
  </div>

  <div
    class="relative grid grow grid-cols-[minmax(auto,280px)_1fr] gap-6 overflow-hidden"
  >
    <nav aria-label="Question groups" class="overflow-y-auto pr-1.5">
      <ol role="list">
        @for (
          submodule of submoduleTypes;
          track submodule;
          let submoduleIndex = $index
        ) {
          @if (groups()[submodule].length) {
            <li
              class="sticky top-0 mt-6 flex items-center justify-between gap-1.5 rounded border bg-gray-50 px-1.5 py-0.5 capitalize first:mt-0"
            >
              <h2 class="truncate text-sm text-gray-500">
                Phase {{ submoduleIndex + 1 }} &mdash; {{ submodule }}
              </h2>
              <p class="text-xs text-gray-400">
                ({{ groups()[submodule].length }})
              </p>
            </li>

            <ol role="list" class="pt-3">
              @for (group of groups()[submodule]; track group.groupKey) {
                <li class="py-px first:pt-0">
                  <button
                    [attr.aria-selected]="group.groupKey === selectedGroupKey()"
                    (click)="selectSubmoduleGroup(submodule, group.groupKey)"
                    class="aria-selected:text-dms-green aria-selected:bg-dms-green/10 w-full rounded px-1.5 py-px text-left font-medium transition hover:bg-gray-100"
                  >
                    {{ group.groupLabel }}
                  </button>
                </li>
              }
            </ol>
          }
        }

        <!-- Add scroll padding when overflowing -->
        <li aria-hidden="true" class="h-6"></li>
      </ol>
    </nav>

    <div class="overflow-y-auto pr-1.5">
      <div
        class="sticky top-0 flex items-center justify-between gap-1.5 rounded border bg-gray-50 px-1.5 py-0.5 capitalize"
      >
        <h2 class="truncate text-sm text-gray-500">
          Questions &mdash; {{ selectedGroup()?.groupLabel }}
        </h2>
        <p class="text-xs text-gray-400">
          ({{ selectedGroup()?.questions?.length }})
        </p>
      </div>

      <div class="px-1.5 pt-3">
        <dominion-question-list
          [groups]="groups()"
          [questions]="selectedGroup()?.questions"
          [selectedModule]="selectedModule()"
          [selectedSubmodule]="selectedSubmodule()"
          [selectedGroupKey]="selectedGroupKey()"
          [expandedQuestionKey]="expandedQuestionKey()"
          [selectedQuestionKey]="selectedQuestionKey()"
          [selectedQuestion]="selectedQuestion()"
        ></dominion-question-list>
      </div>

      <!-- Add scroll padding when overflowing -->
      <div class="h-6"></div>
    </div>
  </div>
</div>

import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  ServiceMaintenanceData,
  serviceMaintenanceStructure,
} from './service-maintenance-data.interfaces';

export class ServiceMaintenanceSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'service-maintenance'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'service' as const;
  submoduleType = 'maintenance' as const;
  discriminator = 'service-maintenance' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupBodyShopRO: {
          total: 0,
          complete: 0,
        },
        groupCustomerPayRO: {
          total: 0,
          complete: 0,
        },
        groupExtendedServiceRO: {
          total: 0,
          complete: 0,
        },
        groupInternalPayRO: {
          total: 0,
          complete: 0,
        },
        groupLaborRatesList: {
          total: 0,
          complete: 0,
        },
        groupOpCodes: {
          total: 0,
          complete: 0,
        },
        groupQuickLubeRO: {
          total: 0,
          complete: 0,
        },
        groupServiceDisclaimer: {
          total: 0,
          complete: 0,
        },
        groupServiceDiscounts: {
          total: 0,
          complete: 0,
        },
        groupServiceFees: {
          total: 0,
          complete: 0,
        },
        groupSkillSets: {
          total: 0,
          complete: 0,
        },
        groupWarrantyPayRO: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: ServiceMaintenanceData;
  override structure = serviceMaintenanceStructure['groups'];
  constructor(initial?: ICoreSubmodule<'service-maintenance'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      this.data = new ServiceMaintenanceData();
    }
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { futureDateValidator } from '../../../validators/future-date.validator';
import { CompanyService } from '../../../company/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ICompanyCreateDto, IDMSList, StateList } from '@dominion/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'dominion-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css'],
})
export class CreateCompanyComponent {
  public companyForm: FormGroup;
  public saving: boolean = false;
  public DMSs = IDMSList;
  public states = StateList;
  public isOtherDms: boolean = false;
  public isServerErr: boolean = false;
  public serverErrMsg: string = '';

  constructor(
    private fb: FormBuilder,
    private service: CompanyService,
    private router: Router,
  ) {
    this.companyForm = this.fb.group({
      name: ['', Validators.required],
      street: ['', [Validators.required]],
      line2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required, Validators.pattern(/^[A-Za-z]*$/)]],
      zip: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
      dms: ['', Validators.required],
      contractExpirationDate: [''],
      preferredInstallDate: ['', [futureDateValidator()]],
    });
  }

  onDmsChange() {
    const dms = this.companyForm.get('dms')!.value;
    if (dms === 'other') {
      this.selectOtherDms();
    } else {
      this.removeOtherDms();
    }
  }

  private selectOtherDms() {
    this.companyForm.addControl(
      'otherDms',
      this.fb.control('', Validators.required),
    );
    this.isOtherDms = true;
  }

  private removeOtherDms() {
    this.companyForm.removeControl('otherDms');
    this.isOtherDms = false;
  }

  private handleSuccess() {
    this.saving = false;
    this.router.navigateByUrl('core/admin/companies');
  }

  private handleError(err: HttpErrorResponse) {
    this.saving = false;
    this.isServerErr = true;
    this.serverErrMsg = err.error.message;
  }

  private buildDto() {
    const pid = this.companyForm.get('preferredInstallDate')!.value
      ? new Date(this.companyForm.get('preferredInstallDate')!.value)
      : undefined;
    const ced = new Date(this.companyForm.get('contractExpirationDate')!.value);
    const dto: ICompanyCreateDto = {
      name: this.companyForm.get('name')!.value,
      address: {
        street: this.companyForm.get('street')!.value,
        line2: this.companyForm.get('line2')!.value,
        city: this.companyForm.get('city')!.value,
        state: this.companyForm.get('state')!.value,
        zip: this.companyForm.get('zip')!.value,
      },
      dms:
        this.companyForm.get('dms')!.value === 'other'
          ? this.companyForm.get('otherDms')!.value
          : this.companyForm.get('dms')!.value,
      preferredInstallDate: pid,
      contractExpirationDate: ced,
    };
    return dto;
  }

  private resetServerError() {
    this.isServerErr = false;
    this.serverErrMsg = '';
  }

  submit() {
    if (this.companyForm.valid) {
      this.resetServerError();
      this.saving = true;
      this.service.createCompany(this.buildDto()).subscribe({
        next: () => {
          this.handleSuccess();
        },
        error: (err) => {
          this.handleError(err);
        },
      });
    }
  }
}
/*
- need to figure out ui for select input items
- need to decide what is required and what is not required
- ui tests
- unit tests
- e2e tests
*/

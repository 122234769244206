<!-- DROPDOWN HOST -->
<div
  dominionDropdownHost
  dominionPopoverHost
  [disabled]="isDisabled"
  [searchable]="composedConfig.searchable"
  (opened)="open()"
  (closed)="close()"
  class="relative flex max-w-full flex-row"
>
  <!-- BUTTON TOGGLE -->
  @if (composedConfig.useButtonToggle) {
    <div
      #buttonToggle
      class="flex flex-row justify-center"
      dominionDropdownToggle
      dominionPopoverTarget
    >
      <ng-content select="[buttonToggle]"></ng-content>
    </div>
  }

  <!-- MENU TOGGLE -->
  @if (!composedConfig.useButtonToggle) {
    <div
      #toggle
      dominionDropdownToggle
      dominionPopoverTarget
      [ngClass]="{
        open: this.isOpen,
        'ring-dms-light-green ring ring-offset-1':
          composedConfig.ring && this.isOpen
      }"
      class="[&.editable]:hover:border-dms-green [&.open.editable]:border-dms-mid-green group flex min-w-0 grow cursor-pointer overflow-hidden rounded-sm border border-transparent [&.disabled]:cursor-not-allowed [&.disabled]:bg-gray-100 [&.disabled]:text-gray-500 [&.disabled]:ring-0 [&.editable.disabled]:bg-transparent [&.editable.disabled]:text-current [&.editable.disabled]:hover:border-transparent [&.editable]:hover:border-dashed [&.open.editable]:border-solid [&.placeholder]:italic [&.placeholder]:text-gray-500 {{
        composedConfig.togglePaddingX +
          ' ' +
          composedConfig.togglePaddingY +
          ' ' +
          composedConfig.toggleTextSize
      }}"
      [ngClass]="{ disabled: isDisabled, placeholder: !initialValue }"
    >
      <div class="w-full truncate capitalize">
        {{
          initialValue
            ? (initialValue | getSelectOption: options : 'label')
            : defaultValue
        }}
      </div>

      @if (composedConfig.dropdownCaret) {
        <div class="flex flex-shrink items-center justify-center pl-2">
          <div
            class="group-hover:stroke-dms-green group-[&.open]:stroke-dms-green stroke-gray-400 group-[&.disabled]:stroke-gray-400"
          >
            <dominion-icon-down-caret></dominion-icon-down-caret>
          </div>
        </div>
      }
    </div>
  }

  <!-- ERR MSG POPOVER -->
  <div
    dominionPopover
    popoverTextColor="text-red-700"
    popoverTextSize="text-sm"
  >
    {{ serverErrMsg }}
  </div>

  <!-- DROPDOWN MENU -->
  <div
    dominionDropdownMenu
    class="flex-col hidden relative w-full data-[show]:flex border border-gray-200 {{
      composedConfig.shadowSize
    }} bg-white text-dms-ultra-grey max-h-[250px] overflow-hidden z-50 min-w-[200px]"
  >
    <!-- Create New Button -->
    @if (composedConfig.enableCreateNew) {
      <div
        (click)="createNew()"
        class="p-[2px] flex flex-row justify-center items-center h-[24px] min-w-[24px] shadow-md absolute bottom-1 right-1 rounded-full cursor-pointer [&>div]:w-0 {{
          composedConfig.createNewTextWidth
        }} hover:pl-1 [&>div]:hover:pl-[6px] bg-dms-mid-green"
      >
        <span class="h-4 w-4">
          <dominion-icon-plus strokeColor="text-white"> </dominion-icon-plus>
        </span>
        <div
          class="flex flex-row overflow-hidden whitespace-nowrap p-0 text-sm text-white transition-all duration-300"
        >
          {{ composedConfig.createNewLabel }}
        </div>
      </div>
    }

    <!-- Scrollable List Container -->
    <div
      class="flex flex-1 flex-col overflow-x-hidden overflow-y-scroll font-normal"
    >
      <!-- Search -->
      @if (composedConfig.searchable) {
        <div
          class="sticky top-0 flex flex-none flex-row overflow-hidden bg-white p-2 font-normal"
        >
          <form
            class="border-dms-light-grey focus-within:border-dms-light-green flex flex-1 flex-row items-center overflow-hidden rounded-sm border"
            [formGroup]="filterForm"
          >
            <div
              class="stroke-dms-grey relative mx-1 flex-none basis-[16px] fill-none"
            >
              <dominion-icon-search></dominion-icon-search>
            </div>
            <input
              #filterInputEl
              type="text"
              name="filter"
              formControlName="filter"
              class="flex-grow outline-none"
            />
            @if (filterForm.get('filter')?.value) {
              <div
                (click)="clearFilter()"
                class="hover:text-dms-ultra-grey flex-shrink cursor-pointer px-2 font-light text-gray-500"
              >
                &times;
              </div>
            }
          </form>
        </div>
      }

      <!-- List -->
      @if (!isLoading) {
        @for (
          option of isFilterActive ? filteredOptions : options;
          track option.value
        ) {
          <div
            (click)="save(option.value)"
            [ngClass]="{
              selected: option.value === initialValue,
              disabled: option.disabled
            }"
            class="flex flex-row flex-none max-w-full overflow-hidden hover:bg-dms-light-grey cursor-pointer [&.selected]:text-dms-green [&.selected]:cursor-not-allowed truncate [&.disabled]:cursor-not-allowed [&.disabled]:text-gray-400 [&.disabled]:hover:bg-transparent [&.selected]:hover:bg-transparent {{
              composedConfig.optionsPaddingX +
                ' ' +
                composedConfig.optionsPaddingY +
                ' ' +
                composedConfig.optionsTextSize
            }} {{ composedConfig.capitalizeOptionLabels ? 'capitalize' : '' }}"
          >
            {{ option.label }}
          </div>
        }

        @if (
          isFilterActive ? filteredOptions.length === 0 : options.length === 0
        ) {
          <div class="flex flex-row p-2">
            <span class="text-sm font-light italic text-gray-600"
              >No results found</span
            >
          </div>
        }
      }

      @if (isLoading) {
        <div class="flex flex-col">
          <div class="flex h-8 flex-row p-1">
            <dominion-loading-placeholder
              class="flex-shrink basis-2/3"
            ></dominion-loading-placeholder>
          </div>
          <div class="flex h-8 flex-row p-1">
            <dominion-loading-placeholder
              class="flex-shrink basis-4/5"
            ></dominion-loading-placeholder>
          </div>
          <div class="flex h-8 flex-row p-1">
            <dominion-loading-placeholder
              class="flex-shrink basis-1/2"
            ></dominion-loading-placeholder>
          </div>
        </div>
      }
    </div>
  </div>
</div>

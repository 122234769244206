import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { CommonModule } from '@angular/common';
import { IconLockLockedComponent } from '../../icons/icon-lock-locked.component';

@Component({
  selector: 'dominion-module-action-btn',
  standalone: true,
  imports: [IconGoToComponent, CommonModule, IconLockLockedComponent],
  template: `
    <button
      (click)="handleClick()"
      [title]="isInternalView || isAvailable ? 'Open' : 'Locked'"
      [disabled]="!isAvailable && !isInternalView"
      class="bg-dms-teal flex h-[40px] flex-none cursor-pointer items-center justify-center rounded-md px-2 py-2 text-sm text-white transition-all hover:ring-offset-1 enabled:hover:ring-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-600 lg:px-4"
    >
      @if (isInternalView || isAvailable) {
        <span class="pr-2">Open</span>
        <div class="h-4 w-4">
          <dominion-icon-go-to
            [strokeColor]="'stroke-current'"
          ></dominion-icon-go-to>
        </div>
      } @else {
        <span class="pr-2">Locked</span>
        <div class="h-4 w-4">
          <dominion-icon-lock-locked
            [strokeColor]="'stroke-current'"
          ></dominion-icon-lock-locked>
        </div>
      }
    </button>
  `,
})
export class ModuleActionBtnComponent {
  @HostBinding() class = 'flex-1 flex flex-row justify-center';

  @Input() isInternalView: boolean = false;
  @Input() isAvailable: boolean = false;
  @Input() percentageComplete: number = 0;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  handleClick() {
    if (this.isAvailable || this.isInternalView) {
      this.clicked.emit();
    }
  }
}

import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TSalesDefaultGroupIdentifiers =
  | 'groupSalesDefaultGeneral'
  | 'groupSalesDefaultCommission'
  | 'groupSalesDefaultCreditApp';

export type TSalesDefaultDataKeys =
  | 'saleLender'
  | 'leaseLender'
  | 'newVehicleStockNumber'
  | 'usedVehicleStockNumber'
  | 'vehicleInventoryControlType'
  | 'deskArchiveDays'
  | 'stateTax'
  | 'updateClosedDealCost'
  | 'conjunction'
  | 'zeroEstimatedCost'
  | 'packInWholesale'
  | 'ageInDealRecap'
  | 'showCostsOnDeal'
  | 'holdbackCommission'
  | 'factoryIncentiveCommission'
  | 'printZeroCommissionVouchers'
  | 'hideCommissionBasis'
  | 'dealProfitOnRecap'
  | 'commissionSamePage'
  | 'archiveSubmittedCreditApps'
  | 'archiveNonSubmittedCreditApps';

const salesDefaultGroupMap = {
  moduleType: 'sales',
  submoduleType: 'default',
  discriminator: 'sales-default',
  groups: {
    groupSalesDefaultGeneral: [
      'saleLender',
      'leaseLender',
      'newVehicleStockNumber',
      'usedVehicleStockNumber',
      'vehicleInventoryControlType',
      'deskArchiveDays',
      'stateTax',
      'updateClosedDealCost',
      'conjunction',
      'zeroEstimatedCost',
      'packInWholesale',
      'ageInDealRecap',
      'showCostsOnDeal',
    ],
    groupSalesDefaultCommission: [
      'holdbackCommission',
      'factoryIncentiveCommission',
      'dealProfitOnRecap',
      'commissionSamePage',
      'printZeroCommissionVouchers',
      'hideCommissionBasis',
    ],
    groupSalesDefaultCreditApp: [
      'archiveSubmittedCreditApps',
      'archiveNonSubmittedCreditApps',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TSalesDefaultGroupIdentifiers,
  TSalesDefaultDataKeys
>;

export type TSalesDefaultGroupMap = typeof salesDefaultGroupMap;

export class SalesDefaultData
  implements TDataDefinition<TSalesDefaultDataKeys>
{
  saleLender = new DataInitializerBasic<string>();
  leaseLender = new DataInitializerBasic<string>();
  newVehicleStockNumber = new DataInitializerBasic<
    'last-6' | 'last-8' | 'blank'
  >();
  usedVehicleStockNumber = new DataInitializerBasic<
    'last-6' | 'last-8' | 'blank'
  >();
  vehicleInventoryControlType = new DataInitializerBasic<
    'last-6' | 'last-8' | 'stock'
  >();
  deskArchiveDays = new DataInitializerBasic<string>();
  stateTax = new DataInitializerBasic<'prospect' | 'dealership'>();
  updateClosedDealCost = new DataInitializerBasic<'never' | 'allow'>();
  conjunction = new DataInitializerBasic<'slash' | 'and' | 'or' | 'and-or'>();
  zeroEstimatedCost = new DataInitializerBasic<'zero' | 'acv'>();
  packInWholesale = new DataInitializerBasic<boolean>();
  ageInDealRecap = new DataInitializerBasic<boolean>();
  showCostsOnDeal = new DataInitializerBasic<'never' | 'allow'>();
  holdbackCommission = new DataInitializerBasic<boolean>();
  factoryIncentiveCommission = new DataInitializerBasic<boolean>();
  dealProfitOnRecap = new DataInitializerBasic<boolean>();
  commissionSamePage = new DataInitializerBasic<'same' | 'separate'>();
  archiveSubmittedCreditApps = new DataInitializerBasic<string>();
  archiveNonSubmittedCreditApps = new DataInitializerBasic<string>();
  printZeroCommissionVouchers = new DataInitializerBasic<boolean>();
  hideCommissionBasis = new DataInitializerBasic<boolean>();
}

export const salesDefaultStructure = {
  moduleType: 'sales',
  submoduleType: 'default',
  discriminator: 'sales-default',
  groups: {
    groupSalesDefaultGeneral: {
      groupLabel: 'General',
      groupUrl: 'groupSalesDefaultGeneral',
      groupType: 'standard',
      questions: {
        saleLender: {
          questionKey: 'saleLender',
          label: 'Sale Lender',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a sales deal, VUE should default to which lender?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is only a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        leaseLender: {
          questionKey: 'leaseLender',
          label: 'Lease Lender',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a lease deal, VUE should default to which lender?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is only a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        newVehicleStockNumber: {
          questionKey: 'newVehicleStockNumber',
          label: 'New Vehicle Stock Number',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When adding a ',
            },
            {
              attributes: { bold: true },
              text: 'new ',
            },
            {
              attributes: null,
              text: 'vehicle to inventory, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'Assign the last 6 digits of the VIN as the stock number.',
              value: 'last-6',
            },
            {
              label: 'Assign the last 8 digits of the VIN as the stock number.',
              value: 'last-8',
            },
            {
              label: 'Leave the stock number blank.',
              value: 'blank',
            },
          ],
          validation: [],
          dependencies: [],
        },
        usedVehicleStockNumber: {
          questionKey: 'usedVehicleStockNumber',
          label: 'Used Vehicle Stock Number',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When adding a ',
            },
            {
              attributes: { bold: true },
              text: 'used ',
            },
            {
              attributes: null,
              text: 'vehicle to inventory, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'Assign the last 6 digits of the VIN as the stock number.',
              value: 'last-6',
            },
            {
              label: 'Assign the last 8 digits of the VIN as the stock number.',
              value: 'last-8',
            },
            {
              label: 'Leave the stock number blank.',
              value: 'blank',
            },
          ],
          validation: [],
          dependencies: [],
        },
        vehicleInventoryControlType: {
          questionKey: 'vehicleInventoryControlType',
          label: 'Vehicle Inventory Control Type',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should manage my vehicle inventory using:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Your selection here determines how VUE will control your vehicle inventory. For example, this setting affects how VUE links financial details, like floor plan amounts, to vehicles in inventory.',
            },
          ],
          options: [
            {
              label: 'The last 6 digits of the VIN.',
              value: 'last-6',
            },
            {
              label: 'The last 8 digits of the VIN.',
              value: 'last-8',
            },
            {
              label: "The vehicle's stock number",
              value: 'stock',
            },
          ],
          validation: [],
          dependencies: [],
        },
        deskArchiveDays: {
          questionKey: 'deskArchiveDays',
          label: 'Desk Archive Days',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should automatically archive an open desk after how many days?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For example: If you select 30 days, VUE will automatically mark an open desk as "expired" after 30 days. At that point, only authorized users can restore the desk.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        stateTax: {
          questionKey: 'stateTax',
          label: 'State Tax',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a deal, VUE should automatically calculate state taxes based on:',
            },
          ],
          subprompt: [],
          options: [
            {
              label: "The prospect's state.",
              value: 'prospect',
            },
            {
              label: "The dealership's state.",
              value: 'dealership',
            },
          ],
          validation: [],
          dependencies: [],
        },
        updateClosedDealCost: {
          questionKey: 'updateClosedDealCost',
          label: 'Update Closed Deal Cost',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'After a deal has been closed, VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: "If you choose to allow authorized users to update deal cost, they'll be able to do so until the deal is posted.",
            },
          ],
          options: [
            {
              label: 'Never allow updates to the scheduled cost of the deal.',
              value: 'never',
            },
            {
              label:
                'Allow only authorized users to update the scheduled cost of the deal.',
              value: 'allow',
            },
          ],
          validation: [],
          dependencies: [],
        },
        conjunction: {
          questionKey: 'conjunction',
          label: 'Conjunction',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Based on my state's legal requirements, VUE should list co-buyers on titles and contracts using:",
            },
          ],
          subprompt: [],
          options: [
            {
              label: '"/" (slash)',
              value: 'slash',
            },
            {
              label: '"and"',
              value: 'and',
            },
            {
              label: '"or"',
              value: 'or',
            },
            {
              label: '"and/or"',
              value: 'and-or',
            },
          ],
          validation: [],
          dependencies: [],
        },
        zeroEstimatedCost: {
          questionKey: 'zeroEstimatedCost',
          label: 'Zero Estimated Cost',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a deal closes and the actual cost of a vehicle trade is not available, VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose to use the actual cash value (ACV) of a traded vehicle as its estimated cost.',
            },
          ],
          options: [
            {
              label: 'Set the estimated cost of the trade to zero.',
              value: 'zero',
            },
            {
              label:
                "Set the estimated cost of the trade to the vehicle's actual cash value (ACV).",
              value: 'acv',
            },
          ],
          validation: [],
          dependencies: [],
        },
        packInWholesale: {
          questionKey: 'packInWholesale',
          label: 'Pack In Wholesale',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When preparing a wholesale deal, should VUE include pack amounts in deal calculations?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ],
          validation: [],
          dependencies: [],
        },
        ageInDealRecap: {
          questionKey: 'ageInDealRecap',
          label: 'Age In Deal Recap',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When printing a deal recap, should VUE include the vehicle's age (time on the lot)?",
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ],
          validation: [],
          dependencies: [],
        },
        showCostsOnDeal: {
          questionKey: 'showCostsOnDeal',
          label: 'Show Costs On Deal',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When preparing a deal, VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This setting determines whether users will have the option to show the costs of products, service contracts, insurance, or fees on the deal. Showing or hiding these costs will have no impact on the deal profit.',
            },
          ],
          options: [
            {
              label:
                'Never show costs associated with products, service contracts, insurance, or fees on the deal.',
              value: 'never',
            },
            {
              label:
                'Allow authorized users to show or hide these costs on the deal.',
              value: 'allow',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupSalesDefaultCommission: {
      groupLabel: 'Commission',
      groupUrl: 'groupSalesDefaultCommission',
      groupType: 'standard',
      questions: {
        holdbackCommission: {
          questionKey: 'holdbackCommission',
          label: 'Holdback Commission',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When calculating commissions, should VUE include holdback in the commissionable gross profit?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ],
          validation: [],
          dependencies: [],
        },
        factoryIncentiveCommission: {
          questionKey: 'factoryIncentiveCommission',
          label: 'Factory Incentive Commission',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When calculating commissions, should VUE include factory incentives in the commissionable gross profit?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ],
          validation: [],
          dependencies: [],
        },
        dealProfitOnRecap: {
          questionKey: 'dealProfitOnRecap',
          label: 'Deal Profit On Recap',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a deal recap, should VUE include deal profit',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ],
          validation: [],
          dependencies: [],
        },
        commissionSamePage: {
          questionKey: 'commissionSamePage',
          label: 'Commission Same Page',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing commission vouchers, should VUE print the salesperson commission voucher and the internal commission voucher on the same page or separate pages?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'Separate pages',
              value: 'separate',
            },
            {
              label: 'Same page',
              value: 'same',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printZeroCommissionVouchers: {
          questionKey: 'printZeroCommissionVouchers',
          label: 'Print Zero Dollar Commission Vouchers',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing commission vouchers, should VUE print a voucher for a $0.00 commission?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ],
          validation: [],
          dependencies: [],
        },
        hideCommissionBasis: {
          questionKey: 'hideCommissionBasis',
          label: 'Hide Commission Basis',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing commission vouchers, should VUE hide the basis and adjusted basis amounts?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupSalesDefaultCreditApp: {
      groupLabel: 'Credit Applications',
      groupUrl: 'groupSalesDefaultCreditApp',
      groupType: 'standard',
      questions: {
        archiveSubmittedCreditApps: {
          questionKey: 'archiveSubmittedCreditApps',
          label: 'Archive Submitted Credit Apps',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should automatically archive ',
            },
            {
              attributes: { bold: true },
              text: 'submitted ',
            },
            {
              attributes: null,
              text: 'credit applications after how many days?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This setting applies to credit applications that have been submitted to the third party.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        archiveNonSubmittedCreditApps: {
          questionKey: 'archiveNonSubmittedCreditApps',
          label: 'Archive Non-Submitted Credit Apps',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should automatically archive ',
            },
            {
              attributes: { bold: true },
              text: 'non-submitted ',
            },
            {
              attributes: null,
              text: 'credit applications after how many days?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This setting applies to credit applications that have not been submitted to the third party.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['sales-default'];

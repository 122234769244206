import {
  PartsPreliminaryData,
  partsPreliminaryStructure,
} from '@dominion/interfaces';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import { CoreSubmodule } from '../../core/submodule/core-submodule.class';

export class PartsPreliminarySubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'parts-preliminary'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'parts' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'parts-preliminary' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupMisc: {
          total: 0,
          complete: 0,
        },
        groupVendors: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: PartsPreliminaryData;
  override structure = partsPreliminaryStructure['groups'];
  constructor(initial?: ICoreSubmodule<'parts-preliminary'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new PartsPreliminaryData();
    }
  }
}

import { Component, HostBinding } from '@angular/core';
import { IconHomeComponent } from '../../icons/icon-home.component';
import { IconCompanyComponent } from '../../icons/icon-company.component';
import { IconUserComponent } from '../../icons/icon-user.component';
import { IconHandshakeComponent } from '../../icons/icon-handshake.component';
import { IconCardsComponent } from '../../icons/icon-cards.component';
import { IconSupportComponent } from '../../icons/icon-support.component';

@Component({
  selector: 'dominion-internal-side-nav',
  templateUrl: './admin-side-nav.component.html',
  styleUrls: ['./admin-side-nav.component.css'],
})
export class AdminSideNavComponent {
  @HostBinding() class =
    'border-dms-light-grey flex flex-col border-r pt-8 xl:basis-1/6';
  navItems = [
    {
      label: 'Dashboard',
      route: '/core/admin/dashboard',
      icon: IconHomeComponent,
    },
    {
      label: 'Companies',
      route: '/core/admin/companies',
      icon: IconCompanyComponent,
    },
    {
      label: 'Users',
      route: '/core/admin/users',
      icon: IconUserComponent,
    },
    {
      label: 'Vendors',
      route: '/core/admin/vendors',
      icon: IconHandshakeComponent,
    },
    {
      label: 'Questions',
      route: '/core/admin/questions',
      icon: IconCardsComponent,
    },
    {
      label: 'Support',
      route: '/core/admin/support',
      icon: IconSupportComponent,
    },
  ];
}

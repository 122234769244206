export const ALLOWED_FILE_EXTENSIONS = [
  '.pdf',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.numbers',
  '.pages',
  '.gif',
  '.heic',
  '.jpeg',
  '.jpg',
  '.png',
  '.tiff',
  '.txt',
  '.rtf',
];

export const ALLOWED_FILE_TYPES = [
  'application/pdf', // .pdf
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/msword', // .doc
  'application/x-iwork-numbers-sffnumbers', // .numbers
  'application/x-iwork-pages-sffpages', // .pages
  'image/gif', // .gif
  'image/heic', // .heic
  'image/jpeg', // .jpeg, .jpg
  'image/png', // .png
  'image/tiff', // .tiff
  'text/plain', // .txt
  'text/rtf', // .rtf
];

import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import {
  SalesPreliminaryData,
  salesPreliminaryStructure,
} from './sales-prelim-data.interfaces';

export class SalesPreliminarySubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'sales-preliminary'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'sales' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'sales-preliminary' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupVendors: {
          total: 0,
          complete: 0,
        },
        groupDesking: {
          total: 0,
          complete: 0,
        },
        groupMisc: {
          total: 0,
          complete: 0,
        },
        groupOutOfStateSales: {
          total: 0,
          complete: 0,
        },
        groupProposals: {
          total: 0,
          complete: 0,
        },
        groupSalesPeople: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: SalesPreliminaryData;
  override structure = salesPreliminaryStructure['groups'];
  constructor(initial?: ICoreSubmodule<'sales-preliminary'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new SalesPreliminaryData();
    }
  }
}

<div
  class="aspect-square h-full w-full"
  [ngClass]="{ 'cursor-pointer': canEditModuleDetails }"
>
  <div
    class="absolute -bottom-[10px] flex w-full flex-row justify-center text-xs text-gray-600 lg:hidden"
  >
    <span
      class="flex h-4 w-4 flex-row items-center justify-center rounded-full border bg-white"
      >{{ stage }}</span
    >
  </div>
  @if (isLocked) {
    <!-- LOCKED -->
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="h-full w-full rounded-md bg-[#f0f2f4] p-[20%]"
      >
        <dominion-icon-lock-locked
          [strokeColor]="'stroke-gray-500'"
        ></dominion-icon-lock-locked>
      </div>
      <div dominionPopover class="w-48">
        <span class=""> Phase {{ stage }} is locked.</span>
        @if (canEditModuleDetails) {
          <span> Click to unlock.</span>
        }
      </div>
    </div>
  } @else if (completion < 100) {
    <!-- INCOMPLETE -->
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="h-full w-full rounded-md bg-[#e7f3d8] p-[23%]"
      >
        <dominion-icon-ellipsis
          [strokeColor]="'stroke-[#6b9c30]'"
        ></dominion-icon-ellipsis>
      </div>
      <div dominionPopover class="w-48">
        <span>Phase {{ stage }} is {{ completion }}% complete.</span>
        @if (canEditModuleDetails) {
          <span> Click to lock.</span>
        }
      </div>
    </div>
  } @else if (completion === 100) {
    <!-- COMPLETE -->
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="h-full w-full rounded-md bg-slate-200 p-[28%]"
      >
        <dominion-icon-check
          [strokeColor]="'stroke-slate-500'"
        ></dominion-icon-check>
      </div>
      <div dominionPopover class="w-48">
        <span>Phase {{ stage }} is complete.</span>
      </div>
    </div>
  }
</div>

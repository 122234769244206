import { IInvitedUser } from '../user/invited-user.interfaces';
import {
  IExternalAssignments,
  IUserAuthorizations,
} from '../user/user.interfaces';
import { FakeDocumentCore } from './fake-doc-core';

export class FakeInvitedUser extends FakeDocumentCore implements IInvitedUser {
  _id: string = 'iu1';
  email: string = 'invited@email.com';
  authorizations: IUserAuthorizations = {};
  invite: { invitedOn: Date; invitedBy: string } = {
    invitedOn: new Date(),
    invitedBy: 'u1',
  };
  status: 'invited';
  isDeactivated: boolean = false;
  companies: string[] = [];
  userType: 'external';
  external: { assignments: IExternalAssignments[] } = { assignments: [] };

  constructor(initializer?: Partial<IInvitedUser>) {
    super();
    if (initializer) {
      Object.assign(this, initializer);
    }
  }
}

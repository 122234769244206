import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  AccountingMaintenanceData,
  accountingMaintenanceStructure,
} from './accounting-maintenance-data.interfaces';

export class AccountingMaintenanceSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'accounting-maintenance'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'accounting' as const;
  submoduleType = 'maintenance' as const;
  discriminator = 'accounting-maintenance' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupARDisclaimer: {
          total: 0,
          complete: 0,
        },
        groupAutoEntries: {
          total: 0,
          complete: 0,
        },
        groupCustomerReport: {
          total: 0,
          complete: 0,
        },
        groupDealerLogo: {
          total: 0,
          complete: 0,
        },
        groupIncomeStatement: {
          total: 0,
          complete: 0,
        },
        groupJournals: {
          total: 0,
          complete: 0,
        },
        groupPartsAccountCodes: {
          total: 0,
          complete: 0,
        },
        groupSchedules: {
          total: 0,
          complete: 0,
        },
        groupServiceAccountCodes: {
          total: 0,
          complete: 0,
        },
        groupTrialBalance: {
          total: 0,
          complete: 0,
        },
        groupVehicleSalesPostingTemplates: {
          total: 0,
          complete: 0,
        },
        groupVendorReport: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: AccountingMaintenanceData;
  override structure = accountingMaintenanceStructure['groups'];
  constructor(initial?: ICoreSubmodule<'accounting-maintenance'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new AccountingMaintenanceData();
    }
  }
}

import { Model, Types } from 'mongoose';
import { IVendor } from './vendor.interfaces';
import { ModuleType } from '../modules/core/module/core-module.interfaces';

export interface IVendorReference {
  vendorId: string;
  appName: IVendor['appName'];
  providerName: IVendor['providerName'];
}

export interface IVendorQuestion {
  _id: string;
  questionKey: string;
  moduleType: ModuleType;
  label: string;
  desc: string;
  vendors: IVendorReference[];
}

export interface IVendorQuestionModelOverrides {
  _id: Types.ObjectId;
  vendors: Types.DocumentArray<
    Pick<IVendorReference, 'appName' | 'providerName'> & {
      vendorId: Types.ObjectId;
    }
  >;
}

export interface IVendorQuestionModel
  extends Model<IVendorQuestion, {}, IVendorQuestionModelOverrides> {}

// CREATE

export interface IVendorQuestionCreateDto {
  _id: never;
  moduleType: IVendorQuestion['moduleType'];
  questionKey: IVendorQuestion['questionKey'];
  label: IVendorQuestion['label'];
  desc?: IVendorQuestion['desc'];
}

// UPDATE

export interface IVendorQuestionUpdateDto {
  vendorId: string;
  questionKey: IVendorQuestion['questionKey'];
  label: IVendorQuestion['label'];
  desc?: IVendorQuestion['desc'];
}

export interface IVendorQuestionUpdateVendorsDto {
  vendorQuestionId: string;
  vendorId: string;
}

// READ

export interface IVendorQuestionGetByKeysDto {
  questionKeys: IVendorQuestion['questionKey'][];
}

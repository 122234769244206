import {
  Component,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  DataInitializerBasic,
  ICoreModule,
  ICoreSubmodule,
  IRequestUserPayload,
  IUserReadPrivateBrief,
  TGenericGroup,
  TSubmoduleDiscriminators,
  accountingPreliminaryStructure,
  infraPreliminaryStructure,
  isKeyOf,
  partsPreliminaryStructure,
  servicePreliminaryStructure,
} from '@dominion/interfaces';
import { CompanyService } from '../company/company.service';
import { ModalComponent } from '../shared/modal/modal.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CompletionStatusBarComponent } from '../shared/completion-status-bar/completion-status-bar.component';
import { HubspotTrackingService } from '../services/hubspot-tracking.service';
import { TableComponent } from '../shared/table/table.component';
import { CoreCardComponent } from '../shared/core-card/core-card.component';
import { partsMaintenanceStructure } from 'libs/interfaces/src/lib/modules/parts/maintenance/parts-maintenance-data.interfaces';
import { salesPreliminaryStructure } from 'libs/interfaces/src/lib/modules/sales/prelim/sales-prelim-data.interfaces';
import { salesMaintenanceStructure } from 'libs/interfaces/src/lib/modules/sales/maintenance/sales-maintenance-data.interfaces';
import { accountingMaintenanceStructure } from 'libs/interfaces/src/lib/modules/accounting/maintenance/accounting-maintenance-data.interfaces';
import { fiPreliminaryStructure } from 'libs/interfaces/src/lib/modules/fi/prelim/fi-prelim-data.interfaces';
import { serviceMaintenanceStructure } from 'libs/interfaces/src/lib/modules/service/maintenance/service-maintenance-data.interfaces';
import { payrollPreliminaryStructure } from 'libs/interfaces/src/lib/modules/payroll/prelim/payroll-prelim-data.interfaces';
import { payrollMaintenanceStructure } from 'libs/interfaces/src/lib/modules/payroll/maintenance/payroll-maintenance-data.interfaces';
import { UserService } from '../user/user.service';
import { ModuleTypePipe } from '../shared/pipes/module-type.pipe';
import { ModuleIdService } from '../services/module-id.service';
import { AuthenticationService } from '../auth/authentication.service';
import { SubmodulePhasePipe } from '../shared/pipes/submodule-phase.pipe';
import { IconLockLockedComponent } from '../icons/icon-lock-locked.component';
import { QuestionPipe } from '../shared/pipes/question.pipe';
import { PromptPipe } from '../shared/pipes/prompt.pipe';
import { QuestionDataPipe } from '../shared/pipes/question-data.pipe';
import { GroupPipe } from '../shared/pipes/group.pipe';
import { partsDefaultStructure } from 'libs/interfaces/src/lib/modules/parts/default/parts-default-data.interfaces';
import { serviceDefaultStructure } from 'libs/interfaces/src/lib/modules/service/default/service-default-data.interfaces';
import { accountingDefaultStructure } from 'libs/interfaces/src/lib/modules/accounting/default/accounting-default-data.interfaces';
import { salesDefaultStructure } from 'libs/interfaces/src/lib/modules/sales/default/sales-default-data.interfaces';
import * as saveAs from 'file-saver';
import { payrollDefaultStructure } from 'libs/interfaces/src/lib/modules/payroll/default/payroll-default-data.interfaces';

@Component({
  selector: 'dominion-locked-submodule-card',
  standalone: true,
  imports: [CoreCardComponent, IconLockLockedComponent],
  template: `
    <dominion-core-card [id]="id" [title]="title" [hasPadding]="false">
      <div class="flex w-full items-center gap-2 border-t p-4">
        <div class="h-4 w-4 text-gray-300">
          <dominion-icon-lock-locked
            [strokeColor]="'stroke-gray-400'"
          ></dominion-icon-lock-locked>
        </div>
        <span class="text-sm text-gray-400">Locked</span>
      </div>
    </dominion-core-card>
  `,
})
export class LockedSubmoduleCardComponent {
  @Input() title: string;

  get id() {
    // Get url friendly id
    return this.title.replace(/[^a-zA-Z0-9- ]/g, '').replace(/ /g, '-');
  }
}

@Component({
  selector: 'dominion-discovery-module-dashboard',
  templateUrl: './discovery-module-dashboard.component.html',
  styleUrls: ['./discovery-module-dashboard.component.css'],
  imports: [
    CommonModule,
    QuestionPipe,
    PromptPipe,
    RouterLink,
    QuestionDataPipe,
    GroupPipe,
    SharedModule,
    CompletionStatusBarComponent,
    TableComponent,
    CoreCardComponent,
    ModuleTypePipe,
    SubmodulePhasePipe,
  ],
  standalone: true,
})
export class DiscoveryModuleDashboardComponent implements OnInit {
  user: IRequestUserPayload | undefined;
  companyId?: string;
  moduleId?: string;
  submodules: Array<ICoreSubmodule<TSubmoduleDiscriminators>> = [];
  module?: ICoreModule;
  modalGroup?: TGenericGroup<string>;
  hasResponses: Record<string, boolean> = {};
  assignedUser?: IUserReadPrivateBrief;
  isLoadingAssignedUser: boolean = true;

  @HostBinding('class') class = 'w-full overflow-auto';
  @ViewChildren('responseModal') responseModals: QueryList<ModalComponent>;

  // submodule ui maps
  maps = {
    'parts-preliminary': partsPreliminaryStructure,
    'parts-maintenance': partsMaintenanceStructure,
    'sales-preliminary': salesPreliminaryStructure,
    'sales-maintenance': salesMaintenanceStructure,
    'accounting-preliminary': accountingPreliminaryStructure,
    'accounting-maintenance': accountingMaintenanceStructure,
    'fi-preliminary': fiPreliminaryStructure,
    'service-preliminary': servicePreliminaryStructure,
    'service-maintenance': serviceMaintenanceStructure,
    'payroll-preliminary': payrollPreliminaryStructure,
    'payroll-maintenance': payrollMaintenanceStructure,
    'infrastructure-preliminary': infraPreliminaryStructure,
    'parts-default': partsDefaultStructure,
    'service-default': serviceDefaultStructure,
    'accounting-default': accountingDefaultStructure,
    'sales-default': salesDefaultStructure,
    'payroll-default': payrollDefaultStructure,
  };

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private userService: UserService,
    private hubspot: HubspotTrackingService,
    private moduleIdService: ModuleIdService,
  ) {
    this.hubspot.trackPageView('/discovery-module-dashboard');
  }

  getGroupCompletionStatus(
    submodule: ICoreSubmodule<TSubmoduleDiscriminators>,
    groupKey: string,
  ): number {
    if (submodule && isKeyOf(submodule.meta.completion.groups, groupKey)) {
      const { total, complete } = submodule.meta.completion.groups[groupKey];
      return Math.ceil((complete / total) * 100);
    }
    return 0;
  }

  getGroupActionLabel(
    submodule: ICoreSubmodule<TSubmoduleDiscriminators>,
    groupKey: string,
  ): string {
    if (submodule && isKeyOf(submodule.meta.completion.groups, groupKey)) {
      const { total, complete } = submodule.meta.completion.groups[groupKey];
      if (complete >= total) {
        return 'Revisit';
      } else if (complete === 0) {
        return 'Start';
      } else {
        return 'Continue';
      }
    }
    return 'Start';
  }

  getGroupLink(
    submodule: ICoreSubmodule<TSubmoduleDiscriminators>,
    groupUrl: string,
  ) {
    return [
      '/core',
      this.companyId,
      'module',
      this.moduleId,
      submodule.discriminator,
      submodule._id,
      groupUrl,
    ];
  }

  isDataInitializerMap(
    data: any,
  ): data is Record<string, DataInitializerBasic<any>> {
    return data && typeof data === 'object' && !Array.isArray(data);
  }

  getSubmoduleData() {
    const { companyId, module, moduleId } = this;
    if (!companyId || !module || !moduleId) {
      return;
    }
    // need to make sure that the submodule array order matches the order of module.submodules
    // because the submodules could be returned out of order from the server, we need to manually insert them
    module.submodules.forEach((submoduleRef, i) => {
      this.companyService
        .getSubmoduleData({ submoduleId: submoduleRef.submoduleId })
        .subscribe({
          next: (submodule) => {
            this.submodules[i] = submodule;
          },
          error: (error) => {
            console.error('Could not get the submodule data', error);
          },
        });
    });
  }

  getModuleData() {
    if (this.companyId && this.moduleId) {
      this.companyService
        .getModule({ companyId: this.companyId, moduleId: this.moduleId })
        .subscribe((module) => {
          this.module = module;
          this.getSubmoduleData();
          this.getAssignedUser();
        });
    }
  }

  openResponseModal(group: TGenericGroup<string>, submoduleIndex: number) {
    this.modalGroup = group;
    this.hasResponses[group.groupUrl] = new QuestionPipe()
      .transform(group.questions)
      .some((question) => {
        return new QuestionDataPipe().transform(
          question,
          this.submodules[submoduleIndex].data,
        );
      });

    this.responseModals.toArray()[submoduleIndex].open();
  }

  getAssignedUser() {
    if (this.module?.externalAssigned[0])
      this.userService
        .getUserDetail(this.module.externalAssigned[0])
        .subscribe({
          next: (user) => {
            this.assignedUser = user;
            this.isLoadingAssignedUser = false;
          },
        });
  }

  getAssignedUserPath() {
    if (!this.assignedUser || !this.user) {
      return '#!';
    }
    if (this.user.userType === 'internal') {
      return ['/core/admin/users', this.assignedUser._id];
    }
    return ['/core', this.companyId, 'users', this.assignedUser._id];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['companyid'];
      this.moduleId = params['moduleid'];
      this.moduleIdService.setModuleId(params['moduleid']);
      this.getModuleData();
    });
    this.authService.user.subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  getSubmoduleExport(submoduleId: string) {
    this.companyService.getSubmoduleExport(submoduleId).subscribe({
      next: (res) => {
        const disp = res.headers.get('Content-Disposition');
        if (disp) {
          const match = disp.match(/"([^"]*)"/g);
          if (match && match.length > 0) {
            const filename = match[0].replace(/"/g, '');
            saveAs(res.body as Blob, filename);
            return;
          }
        }
        saveAs(
          res.body as Blob,
          `${submoduleId}-${new Date().toDateString()}.csv`,
        );
      },
    });
  }
}

<div dominionCoreOutletTitle class="p-4">Companies</div>
<div class="text-dms-teal mb-8 flex justify-center text-2xl font-bold">
  Create a Company
</div>
<div class="mx-auto flex w-full max-w-xl px-4">
  <form
    class="w-full flex-col space-y-2"
    [formGroup]="companyForm"
    (ngSubmit)="submit()"
  >
    <!-- SERVER ERROR MESSAGE -->
    <div *ngIf="isServerErr" class="flex w-full flex-col">
      <div dominionStandardFormError>
        {{ serverErrMsg }}
      </div>
    </div>

    <!-- COMPANY NAME -->
    <div class="flex w-full flex-col">
      <div
        dominionStandardFormLabel
        [required]="true"
        *ngIf="
          !(
            companyForm.get('name')!.invalid && companyForm.get('name')!.touched
          )
        "
        data-testid="name-label"
      >
        Company Name
      </div>
      <div
        *ngIf="
          companyForm.get('name')!.invalid && companyForm.get('name')!.touched
        "
        dominionStandardFormError
        data-testid="name-error"
      >
        Please enter a company name.
      </div>
      <input
        #name
        dominionStandardTextInput
        type="text"
        formControlName="name"
        placeholder="Dominion Motors LLC"
        data-testid="name-input"
      />
    </div>

    <!-- ADDRESS -->
    <div dominionStandardFormSectionLabel>Address</div>

    <!-- STREET -->
    <div class="flex w-full flex-col">
      <div
        dominionStandardFormLabel
        [required]="true"
        *ngIf="
          !(
            companyForm.get('street')!.invalid &&
            companyForm.get('street')!.touched
          )
        "
        data-testid="street-label"
      >
        Street Address
      </div>
      <div
        *ngIf="
          companyForm.get('street')!.invalid &&
          companyForm.get('street')!.touched
        "
        dominionStandardFormError
        data-testid="street-error"
      >
        Please enter a street address.
      </div>
      <input
        #street
        dominionStandardTextInput
        type="text"
        formControlName="street"
        placeholder="1515 S Federal Hwy"
        data-testid="street-input"
      />
    </div>

    <!-- LINE 2 -->
    <div class="flex w-full flex-col">
      <div dominionStandardFormLabel>PO Box or Suite</div>
      <input
        #line2
        dominionStandardTextInput
        type="text"
        formControlName="line2"
        placeholder="Suite 406"
        data-testid="line2-input"
      />
    </div>

    <!-- CITY -->
    <div class="flex w-full flex-col">
      <div
        dominionStandardFormLabel
        [required]="true"
        *ngIf="
          !(
            companyForm.get('city')!.invalid && companyForm.get('city')!.touched
          )
        "
        data-testid="city-label"
      >
        City
      </div>
      <div
        *ngIf="
          companyForm.get('city')!.invalid && companyForm.get('city')!.touched
        "
        dominionStandardFormError
        data-testid="city-error"
      >
        Please enter a city.
      </div>
      <input
        #city
        dominionStandardTextInput
        type="text"
        formControlName="city"
        placeholder="Boca Raton"
        data-testid="city-input"
      />
    </div>

    <!-- STATE AND ZIP -->
    <div class="flex w-full flex-row space-x-1">
      <div class="flex flex-auto basis-1/2 flex-col">
        <div
          dominionStandardFormLabel
          [required]="true"
          *ngIf="
            !(
              companyForm.get('state')!.invalid &&
              companyForm.get('state')!.touched
            )
          "
          data-testid="state-label"
        >
          State
        </div>
        <div
          *ngIf="
            companyForm.get('state')!.invalid &&
            companyForm.get('state')!.touched
          "
          dominionStandardFormError
          data-testid="state-error"
        >
          Please select a state.
        </div>
        <select
          #state
          dominionStandardFormSelect
          formControlName="state"
          data-testid="state-select"
        >
          <option value="" disabled selected>Select a state</option>
          <option *ngFor="let state of states" [value]="state">
            {{ state | state }}
          </option>
        </select>
      </div>
      <div class="flex flex-auto basis-1/2 flex-col">
        <div
          dominionStandardFormLabel
          [required]="true"
          *ngIf="
            !(
              companyForm.get('zip')!.invalid && companyForm.get('zip')!.touched
            )
          "
          data-testid="zip-label"
        >
          Zip Code
        </div>
        <div
          *ngIf="
            companyForm.get('zip')!.invalid && companyForm.get('zip')!.touched
          "
          dominionStandardFormError
          data-testid="zip-error"
        >
          Please enter a valid zip code.
        </div>
        <input
          #zip
          dominionStandardTextInput
          type="text"
          formControlName="zip"
          placeholder="33432"
          maxlength="5"
          data-testid="zip-input"
        />
      </div>
    </div>

    <!-- DEAL INFO -->
    <div dominionStandardFormSectionLabel>Deal Information</div>

    <!-- INCUMBENT DMS -->
    <div class="flex w-full flex-col">
      <div
        dominionStandardFormLabel
        *ngIf="
          !(companyForm.get('dms')!.invalid && companyForm.get('dms')!.touched)
        "
        [required]="true"
        data-testid="dms-label"
      >
        Curent DMS
      </div>
      <div
        *ngIf="
          companyForm.get('dms')!.invalid && companyForm.get('dms')!.touched
        "
        dominionStandardFormError
        data-testid="dms-error"
      >
        Please select the current DMS.
      </div>

      <!-- SELECT -->
      <select
        #dms
        dominionStandardFormSelect
        formControlName="dms"
        (change)="onDmsChange()"
        data-testid="dms-select"
      >
        <option value="" disabled selected>Select a DMS</option>
        <option *ngFor="let dms of DMSs" [value]="dms">{{ dms | dms }}</option>
      </select>

      <!-- OTHER TEXT ENTRY -->
      <ng-container *ngIf="isOtherDms">
        <div
          dominionStandardFormLabel
          *ngIf="
            !(
              companyForm.get('otherDms')!.invalid &&
              companyForm.get('otherDms')!.touched
            )
          "
          [required]="true"
          data-testid="dms-other-label"
        >
          Other DMS Name
        </div>
        <div
          *ngIf="
            companyForm.get('otherDms')!.invalid &&
            companyForm.get('otherDms')!.touched
          "
          dominionStandardFormError
          data-testid="dms-other-err"
        >
          Please select a DMS.
        </div>
        <input
          #otherDms
          dominionStandardTextInput
          type="text"
          formControlName="otherDms"
          placeholder="Other"
          data-testid="dms-other-input"
        />
      </ng-container>
    </div>

    <!-- CONTRACT EXPIRATION DATE -->
    <div class="flex w-full flex-col">
      <div
        dominionStandardFormLabel
        *ngIf="
          !(
            companyForm.get('contractExpirationDate')!.invalid &&
            companyForm.get('contractExpirationDate')!.touched
          )
        "
        data-testid="ced-label"
      >
        Contract Expiration Date
      </div>
      <div
        *ngIf="
          companyForm.get('contractExpirationDate')!.invalid &&
          companyForm.get('contractExpirationDate')!.touched
        "
        dominionStandardFormError
        data-testid="ced-error"
      >
        Please enter a valid date (mm/dd/yyyy).
      </div>
      <input
        #ced
        dominionStandardTextInput
        type="date"
        formControlName="contractExpirationDate"
        data-testid="ced-input"
      />
    </div>

    <!-- PREFERRED INSTALL DATE -->
    <div class="flex w-full flex-col">
      <div
        dominionStandardFormLabel
        *ngIf="
          !(
            companyForm.get('preferredInstallDate')!.invalid &&
            companyForm.get('preferredInstallDate')!.touched
          )
        "
      >
        Preferred Install Date
      </div>
      <div
        *ngIf="
          companyForm.get('preferredInstallDate')!.invalid &&
          companyForm.get('preferredInstallDate')!.touched &&
          companyForm.get('preferredInstallDate')!.errors?.['minLength']
        "
        dominionStandardFormError
      >
        Please enter a valid date (mm/dd/yyyy).
      </div>
      <div
        *ngIf="
          companyForm.get('preferredInstallDate')!.invalid &&
          companyForm.get('preferredInstallDate')!.touched &&
          companyForm.get('preferredInstallDate')!.errors?.['pastDate']
        "
        dominionStandardFormError
      >
        Please enter a date in the future.
      </div>
      <input
        #pid
        dominionStandardTextInput
        type="date"
        formControlName="preferredInstallDate"
      />
    </div>

    <div class="flex w-full flex-row pt-4">
      <!-- CANCEL -->
      <button
        routerLink="/core/internal/companies"
        type="button"
        dominionStandardFormCancelBtn
      >
        Cancel
      </button>
      <!-- SUBMIT -->
      <dominion-standard-form-submit
        type="submit"
        text="Save"
        [isLoading]="saving"
        [disabled]="companyForm.invalid"
        data-testid="submit-btn"
      ></dominion-standard-form-submit>
    </div>
  </form>
</div>

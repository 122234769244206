import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  ServiceDefaultData,
  serviceDefaultStructure,
} from './service-default-data.interfaces';

export class ServiceDefaultSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'service-default'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'service' as const;
  submoduleType = 'default' as const;
  discriminator = 'service-default' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupServiceDefaultGeneral: {
          total: 0,
          complete: 0,
        },
        groupServiceDefaultFinancial: {
          total: 0,
          complete: 0,
        },
        groupServiceDefaultPrinting: {
          total: 0,
          complete: 0,
        },
        groupServiceDefaultOilLabel: {
          total: 0,
          complete: 0,
        },
        groupServiceDefaultStateDisclosure: {
          total: 0,
          complete: 0,
        },
        groupServiceDefaultDeclined: {
          total: 0,
          complete: 0,
        },
        groupServiceDefaultOpCodes: {
          total: 0,
          complete: 0,
        },
        groupServiceDefaultAdvisors: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: ServiceDefaultData;
  override structure = serviceDefaultStructure['groups'];
  constructor(initial?: ICoreSubmodule<'service-default'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      this.data = new ServiceDefaultData();
    }
  }
}

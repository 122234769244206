import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  private queryParamsSubject: BehaviorSubject<URLSearchParams>;

  constructor(private location: Location) {
    this.queryParamsSubject = new BehaviorSubject<URLSearchParams>(
      new URLSearchParams(this.location.path().split('?')[1] ?? ''),
    );

    this.location.onUrlChange((url) => {
      this.updateQueryParams(url);
    });
  }

  /**
   * Set query parameters on the current url.
   * @param params An object representing key-value pairs of query parameters.
   *
   * @example
   *
   *     setQueryParams({ view: 'items', group: 'featured' })
   */
  setQueryParams(
    params: { [key: string]: string | number | boolean },
    fragment?: string,
  ) {
    const currentPath = this.location.path().split('?')[0];
    const queryParams = new URLSearchParams(
      this.location.path().split('?')[1] ?? '',
    );

    Object.keys(params).forEach((key) => {
      queryParams.set(key, params[key].toString());
    });

    if (fragment) {
      this.location.go(currentPath, queryParams.toString() + `#${fragment}`);
    } else {
      this.location.go(currentPath, queryParams.toString());
    }

    this.updateQueryParams(this.location.path());
  }

  /**
   * Remove specific query parameters from the url.
   * @param keys An array of query parameter keys to remove.
   *
   * @example
   *
   *     removeQueryParams(['group'])
   */
  removeQueryParams(keys: string[]) {
    const currentPath = this.location.path().split('?')[0];
    const searchParams = new URLSearchParams(
      this.location.path().split('?')[1],
    );

    keys.forEach((key) => searchParams.delete(key));

    this.location.go(currentPath, searchParams.toString());

    this.updateQueryParams(this.location.path());
  }

  /**
   * Clear all query parameters.
   */
  clearQueryParams() {
    const currentPath = this.location.path().split('?')[0];
    this.location.go(currentPath);

    this.updateQueryParams(this.location.path());
  }

  /**
   * Subscibe to query param changes.
   */
  subscribeToQueryParams(): Observable<URLSearchParams> {
    return this.queryParamsSubject.asObservable();
  }

  /**
   * Sync query params on change.
   */
  private updateQueryParams(url: string) {
    const queryParams = new URLSearchParams(url.split('?')[1] ?? '');
    this.queryParamsSubject.next(queryParams);
  }

  /**
   * Return current document fragment.
   */
  getFragment(): string {
    return this.location.path(true).split('#')[1];
  }

  /**
   * Scroll to document fragment.
   * @param fragment Document fragment id
   *
   * @example
   *
   *     scrollToFragment('target')
   */
  scrollToFragment(fragment: string) {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

import { ModuleType } from '../modules/core/module/core-module.interfaces';

export function moduleTypeToLabel(moduleType: ModuleType): string {
  switch (moduleType) {
    case 'accounting':
      return 'Accounting';
    case 'sales':
      return 'Sales';
    case 'fi':
      return 'F&I';
    case 'service':
      return 'Service';
    case 'parts':
      return 'Parts';
    case 'infrastructure':
      return 'Infrastructure';
    case 'payroll':
      return 'Payroll';
    default:
      return 'Unknown Module';
  }
}

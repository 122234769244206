import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  SalesDefaultData,
  salesDefaultStructure,
} from './sales-default-data.interfaces';

export class SalesDefaultSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'sales-default'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'sales' as const;
  submoduleType = 'default' as const;
  discriminator = 'sales-default' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupSalesDefaultGeneral: {
          total: 0,
          complete: 0,
        },
        groupSalesDefaultCommission: {
          total: 0,
          complete: 0,
        },
        groupSalesDefaultCreditApp: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: SalesDefaultData;
  override structure = salesDefaultStructure['groups'];
  constructor(initial?: ICoreSubmodule<'sales-default'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      this.data = new SalesDefaultData();
    }
  }
}

import { QuestionComponentType } from '../modules/core/question-component-types.interfaces';
import { IFileUploadReference } from '../uploads/upload.interfaces';
import { IVendorReference } from '../vendor/vendor-question.interfaces';
import { IAddress } from './address.interfaces';
import { IContact } from './contact.interfaces';

export function stringifyDiscoveryResponse(
  componentType: QuestionComponentType,
  options: { label: string; value: unknown }[],
  value: unknown,
): string {
  if (
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return '---';
  }
  switch (componentType) {
    case 'address':
      return stringifyAddress(value);
    case 'contact':
      return stringifyContact(value);
    case 'date':
      return value.toString();
    case 'dropdown':
      return value.toString();
    case 'dropdown-multi':
      return value.toString();
    case 'file':
      return stringifyFile(value);
    case 'file-multi':
      return stringifyFile(value);
    case 'text':
      return value.toString();
    case 'textarea':
      return value.toString();
    case 'month-day':
      return value.toString();
    case 'select':
      return stringifySelect(value, options);
    case 'select-list':
      return stringifySelect(value, options);
    case 'select-multi':
      return stringifySelect(value, options);
    case 'vendor':
      return stringifyVendor(value);
    default:
      return value.toString();
  }
}

// ADDRESS

function isAddress(value: unknown): value is IAddress {
  if (typeof value === 'object' && value !== null && 'street' in value) {
    return true;
  }
  return false;
}

function stringifyAddress(value: unknown): string {
  if (isAddress(value)) {
    return `${value.street}, ${value.city}, ${value.state} ${value.zip}`;
  }
  return '';
}

// CONTACT

function isContact(value: unknown): value is IContact {
  if (
    typeof value === 'object' &&
    value !== null &&
    'orgName' in value &&
    'contactName' in value
  ) {
    return true;
  }
  return false;
}

function stringifyContact(value: unknown): string {
  if (isContact(value)) {
    return Object.values(value).join('\n');
  }
  return '';
}

// FILE

function isFile(value: unknown): value is IFileUploadReference[] {
  if (
    Array.isArray(value) &&
    value.every((v) => 'dateUploaded' in v && 'name' in v)
  ) {
    return true;
  }
  return false;
}

function stringifyFile(value: unknown): string {
  if (isFile(value)) {
    return value.map((v) => v.name).join(', ');
  }
  return '';
}

// SELECT

function stringifySelect(
  value: unknown,
  options: { label: string; value: unknown }[],
): string {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    const option = options.find((o) => o.value === value);
    if (option) {
      return option.label;
    }
  }
  if (Array.isArray(value)) {
    return value
      .map((v) => {
        const option = options.find((o) => o.value === v);
        if (option) {
          return option.label;
        }
        return '';
      })
      .join(', ');
  }
  return '';
}

// VENDOR

function isVendor(value: unknown): value is IVendorReference {
  if (typeof value === 'object' && value !== null && 'vendorId' in value) {
    return true;
  }
  return false;
}

function stringifyVendor(value: unknown): string {
  if (isVendor(value)) {
    if (
      'appName' in value &&
      'providerName' in value &&
      value.vendorId !== null
    ) {
      return `${value.providerName}: ${value.appName}`;
    } else if (value.vendorId === null) {
      return 'None';
    } else {
      return 'Vendor Selected';
    }
  }
  return '';
}

<!-- STATUS BAR -->
<div
  class="absolute -top-3 left-0 hidden h-1 w-[98%] flex-row group-hover/module:flex [&.complete_#status-bar]:bg-slate-400 [&.locked_#status-bar]:bg-gray-300 [&_#status-bar]:bg-[#9ecf63]"
  [ngClass]="{
    complete: (submoduleRef | submoduleStatus) === 100,
    locked: !submoduleRef.isAvailable
  }"
>
  <dominion-completion-status-bar
    [percentage]="submoduleRef | submoduleStatus"
    rounded="rounded-sm"
    completionColor=""
  ></dominion-completion-status-bar>
</div>

<!-- STATUS CIRCLE -->
<div class="row-span-2">
  <dominion-module-status-circle
    (click)="setAvailability()"
    [completion]="submoduleRef | submoduleStatus"
    [canEditModuleDetails]="canEditModuleDetails"
    [isLocked]="!submoduleRef.isAvailable"
    [stage]="position + 1"
  ></dominion-module-status-circle>
</div>

<!-- PHASE DETAILS -->

<div
  class="hidden flex-1 flex-row items-center space-x-2 px-2 text-xs uppercase lg:flex xl:space-x-3 xl:px-4"
>
  <span class="">Phase {{ position + 1 }}</span>

  <!-- Lock Icon -->
  @if (isInternalView) {
    <div class="h-3 w-3 cursor-pointer" (click)="setAvailability()">
      @if (submoduleRef.isAvailable) {
        <dominion-icon-lock-unlocked
          strokeColor="stroke-gray-700"
        ></dominion-icon-lock-unlocked>
      } @else {
        <dominion-icon-lock-locked
          strokeColor="stroke-gray-700"
        ></dominion-icon-lock-locked>
      }
    </div>
  }
</div>

<!-- Due Date -->
<div
  class="hidden flex-1 flex-row items-center px-2 text-xs lg:flex lg:space-x-1 xl:px-4"
>
  <span class="hidden text-xs uppercase text-gray-600 lg:inline-block">
    Due
  </span>
  <dominion-in-situ-text
    #dueDateInput
    type="date"
    [initialValue]="submoduleRef.dueDate | date: 'MM/dd/yyy'"
    [isDisabled]="!canEditModuleDetails"
    (saved)="setDueDate($event, dueDateInput)"
    [defaultValue]="isInternalView ? 'Set Date' : 'Date Pending'"
    formErrMsg="Please enter a valid date (MM/DD/YYYY)"
    [staticClassConfig]="{
      hoverBorderColor: 'hover:border-gray-500'
    }"
  ></dominion-in-situ-text>
</div>

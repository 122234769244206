import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TFIPrelimGroupIdentifiers =
  | 'groupCreditLife'
  | 'groupLeasing'
  | 'groupMisc'
  | 'groupPrinters'
  | 'groupRates'
  | 'groupVendors';

export type TFIPrelimDataKeys =
  | 'sellsCreditLife'
  | 'lenderParticipationRates'
  | 'creditLifeUnderwriting'
  | 'leasesPerMonth'
  | 'leasingLenders'
  | 'doesOnePayLeases'
  | 'onePayLeaseFrequency'
  | 'doesWaiveFirstPaymentLeases'
  | 'doesIrregularOrBalloon'
  | 'electronicTitlingProcess'
  | 'printDealJacket'
  | 'numberImpactPrinters'
  | 'okidataPrinters'
  | 'printerMakeModel'
  | 'usesCUDL'
  | 'fiSignaturePadVendor'
  | 'sellsGap'
  | 'fiMenuVendor'
  | 'fiElectronicTitlingVendor'
  | 'extendedWarrantyContractVendor'
  | 'fiMenuPresentation'
  | 'eContractingVendor';

const fiPrelimGroupMap = {
  moduleType: 'fi',
  submoduleType: 'preliminary',
  discriminator: 'fi-preliminary',
  groups: {
    groupCreditLife: ['sellsCreditLife', 'creditLifeUnderwriting'],
    groupLeasing: [
      'leasesPerMonth',
      'leasingLenders',
      'doesOnePayLeases',
      'onePayLeaseFrequency',
      'doesWaiveFirstPaymentLeases',
      'doesIrregularOrBalloon',
    ],
    groupMisc: [
      'electronicTitlingProcess',
      'printDealJacket',
      'fiMenuPresentation',
      'sellsGap',
    ],
    groupPrinters: [
      'numberImpactPrinters',
      'okidataPrinters',
      'printerMakeModel',
    ],
    groupRates: ['lenderParticipationRates', 'usesCUDL'],
    groupVendors: [
      'fiSignaturePadVendor',
      'fiMenuVendor',
      'fiElectronicTitlingVendor',
      'extendedWarrantyContractVendor',
      'eContractingVendor',
    ],
  },
} satisfies TSubmoduleGroupMap<TFIPrelimGroupIdentifiers, TFIPrelimDataKeys>;

export type TFIPrelimGroupMap = typeof fiPrelimGroupMap;

export class FIPreliminaryData implements TDataDefinition<TFIPrelimDataKeys> {
  // CREDIT LIFE AH
  // groupCreditLife
  sellsCreditLife = new DataInitializerBasic<boolean>();
  creditLifeUnderwriting = new DataInitializerBasic<string>();

  // LEASING
  // groupLeasing
  leasesPerMonth = new DataInitializerBasic<'0-5' | '6-10' | '11-20' | '21+'>();
  leasingLenders = new DataInitializerBasic<string>();
  doesOnePayLeases = new DataInitializerBasic<boolean>();
  onePayLeaseFrequency = new DataInitializerBasic<string>();
  doesWaiveFirstPaymentLeases = new DataInitializerBasic<boolean>();
  doesIrregularOrBalloon = new DataInitializerBasic<boolean>();

  // MISC
  // groupMisc
  electronicTitlingProcess = new DataInitializerBasic<string>();
  printDealJacket = new DataInitializerBasic<string>();
  fiMenuPresentation = new DataInitializerBasic<
    'physical' | 'electronic' | 'other'
  >();

  // PRINTERS
  // groupPrinters
  numberImpactPrinters = new DataInitializerBasic<string>();
  okidataPrinters = new DataInitializerBasic<boolean>();
  printerMakeModel = new DataInitializerBasic<string>();

  // RATES
  // groupRates
  lenderParticipationRates = new DataInitializerBasic<string>();
  usesCUDL = new DataInitializerBasic<boolean>();

  // VENDORS
  // groupVendors
  fiSignaturePadVendor = new DataInitializerBasic<string>();
  fiMenuVendor = new DataInitializerBasic<string>();
  fiElectronicTitlingVendor = new DataInitializerBasic<string>();
  extendedWarrantyContractVendor = new DataInitializerBasic<string>();
  eContractingVendor = new DataInitializerBasic<string>();

  sellsGap = new DataInitializerBasic<boolean>();
}

export const fiPreliminaryStructure = {
  moduleType: 'fi',
  submoduleType: 'preliminary',
  discriminator: 'fi-preliminary',
  groups: {
    groupCreditLife: {
      groupLabel: 'Credit Life A&H',
      groupType: 'standard',
      groupUrl: 'groupCreditLife',
      questions: {
        sellsCreditLife: {
          questionKey: 'sellsCreditLife',
          label: 'Sells Credit Life A&H',
          prompt: [
            {
              text: 'Do you plan to sell credit life A&H?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['creditLifeUnderwriting'],
            },
          ],
        },
        creditLifeUnderwriting: {
          questionKey: 'creditLifeUnderwriting',
          label: 'Credit Life Underwriting',
          prompt: [
            {
              text: 'What underwriting company will be used? Please provide contact information',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupLeasing: {
      groupLabel: 'Leasing',
      groupType: 'standard',
      groupUrl: 'groupLeasing',
      questions: {
        leasesPerMonth: {
          questionKey: 'leasesPerMonth',
          label: 'Leases Per Month',
          prompt: [
            {
              text: 'Approximately how many leases do you do in a month?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: '0-5',
              value: '0-5',
            },
            {
              label: '6-10',
              value: '6-10',
            },
            {
              label: '11-20',
              value: '11-20',
            },
            {
              label: '21+',
              value: '21+',
            },
          ],
          validation: [],
          dependencies: [],
        },
        leasingLenders: {
          questionKey: 'leasingLenders',
          label: 'Leasing Lenders',
          prompt: [
            {
              text: 'Which lenders do you use for leasing?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        doesOnePayLeases: {
          questionKey: 'doesOnePayLeases',
          label: 'One-Pay Leases',
          prompt: [
            {
              text: 'Do you do one-pay leases?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['onePayLeaseFrequency'],
            },
          ],
        },
        onePayLeaseFrequency: {
          questionKey: 'onePayLeaseFrequency',
          label: 'One-Pay Lease Frequency',
          prompt: [
            {
              text: 'How often do you do one-pay leases?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        doesWaiveFirstPaymentLeases: {
          questionKey: 'doesWaiveFirstPaymentLeases',
          label: 'Waive First Payment Leases',
          prompt: [
            {
              text: 'Do you waive the first payment on leases?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        doesIrregularOrBalloon: {
          questionKey: 'doesIrregularOrBalloon',
          label: 'Irregular Deals',
          prompt: [
            {
              text: 'Do you process balloon or irregular payment deals?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },

    groupPrinters: {
      groupLabel: 'Printers',
      groupType: 'standard',
      groupUrl: 'groupPrinters',
      questions: {
        numberImpactPrinters: {
          questionKey: 'numberImpactPrinters',
          label: 'Number of Impact Printers',
          prompt: [
            {
              text: 'How many impact printers do you have?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        okidataPrinters: {
          questionKey: 'okidataPrinters',
          label: 'Okidata Printers',
          prompt: [
            {
              text: 'Are your impact printers Okidata 321T?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['printerMakeModel'],
            },
          ],
        },
        printerMakeModel: {
          questionKey: 'printerMakeModel',
          label: 'Printer Make & Model',
          prompt: [
            {
              text: 'What make and model are your impact printers?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupRates: {
      groupLabel: 'Rates',
      groupType: 'standard',
      groupUrl: 'groupRates',
      questions: {
        lenderParticipationRates: {
          questionKey: 'lenderParticipationRates',
          label: 'Lender Participation Rates',
          prompt: [
            {
              text: 'How do you get your lender participation rates?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        usesCUDL: {
          questionKey: 'usesCUDL',
          label: 'Uses CUDL',
          prompt: [
            {
              text: 'Do you use the CUDL (Credit Union Dealer Lender) platform to check finance rates at credit unions?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupMisc: {
      groupLabel: 'Miscellaneous',
      groupType: 'standard',
      groupUrl: 'groupMisc',
      questions: {
        sellsGap: {
          questionKey: 'sellsGap',
          label: 'Sells GAP',
          prompt: [
            {
              text: 'Do you sell GAP insurance?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        electronicTitlingProcess: {
          questionKey: 'electronicTitlingProcess',
          label: 'Electronic Titling Process',
          prompt: [
            {
              text: 'How does the data get into the electronic titling company for you to submit your title work?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'Do you manually enter it? Is it just there when you enter the VIN? Do you have to pull the data in using the DMS deal number?',
              attributes: null,
            },
          ],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        fiMenuPresentation: {
          questionKey: 'fiMenuPresentation',
          label: 'F&I Menu Presentation',
          prompt: [
            {
              text: 'How do you present F&I menus?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Physical (Paper & Pencil)',
              value: 'physical',
            },
            {
              label: 'Electronic',
              value: 'electronic',
            },
            {
              label: 'Other',
              value: 'other',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printDealJacket: {
          questionKey: 'printDealJacket',
          label: 'Print Deal Jacket',
          prompt: [
            {
              text: 'How do you print a deal jacket?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'NA, Vehicle Deal Envelope (Deal Jacket), Label',
              attributes: null,
            },
          ],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupVendors: {
      groupLabel: 'Vendors',
      groupType: 'vendor',
      groupUrl: 'groupVendors',
      questions: {
        fiSignaturePadVendor: {
          questionKey: 'fiSignaturePadVendor',
          label: 'Signature Pad Vendor',
          prompt: [
            {
              text: 'What signature pad vendor do you plan to use?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        fiMenuVendor: {
          questionKey: 'fiMenuVendor',
          label: 'F&I Menu Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for F&I menus?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        fiElectronicTitlingVendor: {
          questionKey: 'fiElectronicTitlingVendor',
          label: 'Electronic Titling Vendor',
          prompt: [
            {
              text: 'Which electronic titling vendor do you plan to use?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        extendedWarrantyContractVendor: {
          questionKey: 'extendedWarrantyContractVendor',
          label: 'Extended Warranty Contract Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use to price and electronically register or print extended warranty contracts?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        eContractingVendor: {
          questionKey: 'eContractingVendor',
          label: 'eContracting Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for eContracting?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['fi-preliminary'];

export type FIRoutes =
  (typeof fiPreliminaryStructure)['groups'][keyof (typeof fiPreliminaryStructure)['groups']]['groupUrl'];

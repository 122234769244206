import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  PartsDefaultData,
  partsDefaultStructure,
} from './parts-default-data.interfaces';

export class PartsDefaultSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'parts-default'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'parts' as const;
  submoduleType = 'default' as const;
  discriminator = 'parts-default' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupPartsDefaultGeneral: {
          total: 0,
          complete: 0,
        },
        groupPartsDefaultGrossProfit: {
          total: 0,
          complete: 0,
        },
        groupPartsDefaultPrinting: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: PartsDefaultData;
  override structure = partsDefaultStructure['groups'];
  constructor(initial?: ICoreSubmodule<'parts-default'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      this.data = new PartsDefaultData();
    }
  }
}

import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  SalesMaintenanceData,
  salesMaintenanceStructure,
} from './sales-maintenance-data.interfaces';

export class SalesMaintenanceSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'sales-maintenance'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'sales' as const;
  submoduleType = 'maintenance' as const;
  discriminator = 'sales-maintenance' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupCashDeals: {
          total: 0,
          complete: 0,
        },
        groupLaserForms: {
          total: 0,
          complete: 0,
        },
        groupLeaseDeals: {
          total: 0,
          complete: 0,
        },
        groupLenderBulletins: {
          total: 0,
          complete: 0,
        },
        groupRetailDeals: {
          total: 0,
          complete: 0,
        },
        groupVehicleInventory: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: SalesMaintenanceData;
  override structure = salesMaintenanceStructure['groups'];
  constructor(initial?: ICoreSubmodule<'sales-maintenance'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new SalesMaintenanceData();
    }
  }
}

import { CoreSubmodule } from '../../core/submodule/core-submodule.class';
import { ICoreSubmodule } from '../../core/submodule/core-submodule.interfaces';
import {
  PayrollMaintenanceData,
  payrollMaintenanceStructure,
} from './payroll-maintenance-data.interfaces';

export class PayrollMaintenanceSubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'payroll-maintenance'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'payroll' as const;
  submoduleType = 'maintenance' as const;
  discriminator = 'payroll-maintenance' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupDefaultPositionBreakout: {
          total: 0,
          complete: 0,
        },
        groupEmployeeMasterReport: {
          total: 0,
          complete: 0,
        },
        groupPayrollRegisterReport: {
          total: 0,
          complete: 0,
        },
        groupQuarterlyReports: {
          total: 0,
          complete: 0,
        },
        groupQuarterlyTaxReports: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: PayrollMaintenanceData;
  override structure = payrollMaintenanceStructure['groups'];
  constructor(initial?: ICoreSubmodule<'payroll-maintenance'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new PayrollMaintenanceData();
    }
  }
}

import { Component, HostBinding, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { type SearchableQuestion } from '../admin-questions.component';
import { IconSearchComponent } from '../../../icons/icon-search.component';
import { UrlService } from '../../../services/url.service';

@Component({
  selector: 'dominion-question-search',
  standalone: true,
  imports: [FormsModule, IconSearchComponent],
  templateUrl: './question-search.component.html',
  styleUrl: './question-search.component.css',
})
export class QuestionSearchComponent {
  @Input() questions: SearchableQuestion[] = [];
  @Input() search = '';

  @HostBinding('class') class = 'w-full max-w-xs';

  constructor(private urlService: UrlService) {}

  get filteredQuestions(): SearchableQuestion[] {
    const searchLower = this.search.toLowerCase();
    return this.questions.filter(
      ({ questionLabel, questionPrompt, groupKey, questionKey }) =>
        (questionLabel?.toLowerCase().includes(searchLower) ?? false) ||
        (questionPrompt?.toLowerCase().includes(searchLower) ?? false) ||
        (groupKey?.toLowerCase().includes(searchLower) ?? false) ||
        (questionKey?.toLowerCase().includes(searchLower) ?? false),
    );
  }

  selectQuestion(question: SearchableQuestion) {
    this.search = '';
    this.urlService.setQueryParams(
      {
        module: question.module,
        submodule: question.submodule,
        group: question.groupKey,
        question: question.parentKey,
      },
      question.questionKey,
    );

    setTimeout(() => {
      this.urlService.scrollToFragment(question.questionKey);
    });
  }
}

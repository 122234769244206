export interface IDiscoveryExport {
  submoduleId: string;
  moduleType: string;
  phase: string;
  groupLabel: string;
  groupId: string;
  questionLabel: string;
  questionKey: string;
  required: string;
  prompt: string;
  response: string;
  lastEdited: string;
  lastEditedBy: string;
}

import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TAccountingDefaultGroupIdentifiers =
  | 'groupAccountingDefaultGeneral'
  | 'groupAccountingDefaultFinancial'
  | 'groupAccountingDefaultDealPosting'
  | 'groupAccountingDefaultAccountsReceivable'
  | 'groupAccountingDefaultAccountsPayable';

export type TAccountingDefaultDataKeys =
  | 'autoAssignCustomerNumber'
  | 'receiptPostingDescription'
  | 'batchPostingMessage'
  | 'serviceTwoPeriod'
  | 'partsTwoPeriod'
  | 'partsServiceDiscounts'
  | 'tradeEquity'
  | 'dealPostingReference'
  | 'zeroDollarDealItems'
  | 'postCapitalizedUseTax'
  | 'salespersonCommissionPosting'
  | 'fiCommissionPosting'
  | 'salesManagerCommissionPosting'
  | 'arInterestRate'
  | 'minimumFinanceCharge'
  | 'financeIncomeAccount'
  | 'defaultARSchedule'
  | 'printDuplicateReceipts'
  | 'defaultARDueDate'
  | 'printCreditCardReceipt'
  | 'defaultARAccount'
  | 'pAndLAccount'
  | 'defaultAPAccount'
  | 'floorPlanControlType'
  | 'defaultFloorPlanAccount'
  | 'defaultHoldbackAccount';

const accountingDefaultGroupMap = {
  moduleType: 'accounting',
  submoduleType: 'default',
  discriminator: 'accounting-default',
  groups: {
    groupAccountingDefaultGeneral: [
      'autoAssignCustomerNumber',
      'receiptPostingDescription',
      'batchPostingMessage',
      'floorPlanControlType',
      'defaultFloorPlanAccount',
      'defaultHoldbackAccount',
    ],
    groupAccountingDefaultFinancial: [
      'pAndLAccount',
      'serviceTwoPeriod',
      'partsTwoPeriod',
      'partsServiceDiscounts',
    ],
    groupAccountingDefaultDealPosting: [
      'tradeEquity',
      'dealPostingReference',
      'zeroDollarDealItems',
      'postCapitalizedUseTax',
      'salespersonCommissionPosting',
      'fiCommissionPosting',
      'salesManagerCommissionPosting',
    ],
    groupAccountingDefaultAccountsReceivable: [
      'arInterestRate',
      'minimumFinanceCharge',
      'financeIncomeAccount',
      'defaultARSchedule',
      'printDuplicateReceipts',
      'defaultARDueDate',
      'printCreditCardReceipt',
      'defaultARAccount',
    ],
    groupAccountingDefaultAccountsPayable: ['defaultAPAccount'],
  },
} satisfies TSubmoduleGroupMap<
  TAccountingDefaultGroupIdentifiers,
  TAccountingDefaultDataKeys
>;

export type TAccountingDefaultGroupMap = typeof accountingDefaultGroupMap;

export class AccountingDefaultData
  implements TDataDefinition<TAccountingDefaultDataKeys>
{
  autoAssignCustomerNumber = new DataInitializerBasic<boolean>();
  receiptPostingDescription = new DataInitializerBasic<
    'customer-vendor-name' | 'transaction-desc'
  >();
  batchPostingMessage = new DataInitializerBasic<
    'auto-clear' | 'manual-clear'
  >();

  serviceTwoPeriod = new DataInitializerBasic<
    'allow-choice' | 'always-current'
  >();
  partsTwoPeriod = new DataInitializerBasic<
    'allow-choice' | 'always-current'
  >();
  partsServiceDiscounts = new DataInitializerBasic<
    'always-before' | 'always-after' | 'editable-before' | 'editable-after'
  >();
  tradeEquity = new DataInitializerBasic<'cost' | 'price'>();
  dealPostingReference = new DataInitializerBasic<
    'deal-number' | 'stock-number' | 'blank'
  >();
  zeroDollarDealItems = new DataInitializerBasic<boolean>();
  postCapitalizedUseTax = new DataInitializerBasic<boolean>();

  salespersonCommissionPosting = new DataInitializerBasic<
    'employee' | 'employee-and-comm-type'
  >();
  fiCommissionPosting = new DataInitializerBasic<
    'employee' | 'employee-and-comm-type'
  >();
  salesManagerCommissionPosting = new DataInitializerBasic<
    'employee' | 'employee-and-comm-type'
  >();

  arInterestRate = new DataInitializerBasic<number>();
  minimumFinanceCharge = new DataInitializerBasic<number>();
  financeIncomeAccount = new DataInitializerBasic<string>();
  defaultARSchedule = new DataInitializerBasic<string>();
  printDuplicateReceipts = new DataInitializerBasic<boolean>();
  defaultARDueDate = new DataInitializerBasic<number>();
  printCreditCardReceipt = new DataInitializerBasic<'never' | 'user-defined'>();
  defaultARAccount = new DataInitializerBasic<string>();
  defaultAPAccount = new DataInitializerBasic<string>();
  pAndLAccount = new DataInitializerBasic<string>();
  floorPlanControlType = new DataInitializerBasic<
    'last-6' | 'last-8' | 'stock'
  >();
  defaultFloorPlanAccount = new DataInitializerBasic<string>();
  defaultHoldbackAccount = new DataInitializerBasic<string>();
}

export const accountingDefaultStructure = {
  moduleType: 'accounting',
  submoduleType: 'default',
  discriminator: 'accounting-default',
  groups: {
    groupAccountingDefaultGeneral: {
      groupLabel: 'General',
      groupUrl: 'groupAccountingDefaultGeneral',
      groupType: 'standard',
      questions: {
        autoAssignCustomerNumber: {
          questionKey: 'autoAssignCustomerNumber',
          label: 'Auto Assign Customer Number',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a customer, should VUE assign a new customer number automatically?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose "Yes." If you choose "No," you will be required to manually set the customer number.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        receiptPostingDescription: {
          questionKey: 'receiptPostingDescription',
          label: 'Receipt Posting Description',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting receipts, VUE should populate the posting description with:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For example, VUE could populate the posting description with the repair order number or the aftermarket purchased.',
            },
          ],
          options: [
            {
              value: 'customer-vendor-name',
              label: 'The customer or vendor name.',
            },
            {
              value: 'transaction-desc',
              label: 'The description from the transaction.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        batchPostingMessage: {
          questionKey: 'batchPostingMessage',
          label: 'Batch Posting Message',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a batch, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'auto-clear',
              label:
                'Show and automatically clear the batch posting message after a few seconds.',
            },
            {
              value: 'manual-clear',
              label:
                'Show the batch posting message until the user manually clears it.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        floorPlanControlType: {
          questionKey: 'floorPlanControlType',
          label: 'Floor Plan Control Type',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should control floor plan using:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'last-6',
              label: 'The last six digits of the VIN.',
            },
            {
              value: 'last-8',
              label: 'The last eight digits of the VIN.',
            },
            {
              value: 'stock',
              label: "The vehicle's stock number.",
            },
          ],
          validation: [],
          dependencies: [],
        },
        defaultFloorPlanAccount: {
          questionKey: 'defaultFloorPlanAccount',
          label: 'Default Floor Plan Account',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which general ledger account as your default floor plan account?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        defaultHoldbackAccount: {
          questionKey: 'defaultHoldbackAccount',
          label: 'Default Holdback Account',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which general ledger account as your default holdback account?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupAccountingDefaultFinancial: {
      groupLabel: 'Financial',
      groupUrl: 'groupAccountingDefaultFinancial',
      groupType: 'standard',
      questions: {
        pAndLAccount: {
          questionKey: 'pAndLAccount',
          label: 'P&L Account',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which general ledger account to report profit and loss on the financial statement?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        serviceTwoPeriod: {
          questionKey: 'serviceTwoPeriod',
          label: 'Service Two Period',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting ',
            },
            {
              attributes: { bold: true },
              text: 'repair orders (ROs) ',
            },
            {
              attributes: null,
              text: 'while ',
            },
            {
              attributes: { bold: true },
              text: 'two ',
            },
            {
              attributes: null,
              text: 'accounting periods (months) are open, VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For example, your service manager is posting ROs on June 1st that are from May. Should the manager have the option to post to May (Option 1)? Or should the RO always post to June (Option 2)?',
            },
          ],
          options: [
            {
              value: 'allow-choice',
              label:
                "Allow the user to select which period they'd like to post to (this month or last month).",
            },
            {
              value: 'always-current',
              label: 'Always post to the current period (this month).',
            },
          ],
          validation: [],
          dependencies: [],
        },
        partsTwoPeriod: {
          questionKey: 'partsTwoPeriod',
          label: 'Parts Two Period',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting ',
            },
            {
              attributes: { bold: true },
              text: 'parts invoices ',
            },
            {
              attributes: null,
              text: 'while ',
            },
            {
              attributes: { bold: true },
              text: 'two ',
            },
            {
              attributes: null,
              text: 'accounting periods (months) are open, VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For example, your parts manager is posting invoices on June 1st that are from May. Should the manager have the option to post to May (Option 1)? Or should the invoice always post to June (Option 2)?',
            },
          ],
          options: [
            {
              value: 'allow-choice',
              label:
                "Allow the user to select which period they'd like to post to (this month or last month).",
            },
            {
              value: 'always-current',
              label: 'Always post to the current period (this month).',
            },
          ],
          validation: [],
          dependencies: [],
        },
        partsServiceDiscounts: {
          questionKey: 'partsServiceDiscounts',
          label: 'Parts/Service Discounts',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When applying a discount to an RO or invoice, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'always-before',
              label: 'Always apply the discount before tax.',
            },
            {
              value: 'always-after',
              label: 'Always apply the discount after tax.',
            },
            {
              value: 'editable-before',
              label:
                'Apply the discount before tax by default, but let the user change it.',
            },
            {
              value: 'editable-after',
              label:
                'Apply the discount after tax by default, but let the user change it.',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupAccountingDefaultDealPosting: {
      groupLabel: 'Deal Posting',
      groupUrl: 'groupAccountingDefaultDealPosting',
      groupType: 'standard',
      questions: {
        tradeEquity: {
          questionKey: 'tradeEquity',
          label: 'Trade Equity',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a deal with a vehicle trade, should VUE include the trade equity in the vehicle ',
            },
            {
              attributes: { bold: true },
              text: 'price ',
            },
            {
              attributes: null,
              text: 'or ',
            },
            {
              attributes: { bold: true },
              text: 'cost',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'price',
              label: 'Price',
            },
            {
              value: 'cost',
              label: 'Cost',
            },
          ],
          validation: [],
          dependencies: [],
        },
        dealPostingReference: {
          questionKey: 'dealPostingReference',
          label: 'Deal Posting Reference',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a deal, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'deal-number',
              label: 'Populate the reference field with the deal number.',
            },
            {
              value: 'stock-number',
              label: 'Populate the reference field with the stock number.',
            },
            {
              value: 'blank',
              label: 'Leave the reference field blank.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        zeroDollarDealItems: {
          questionKey: 'zeroDollarDealItems',
          label: 'Zero Dollar Deal Items',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a deal, should VUE include $0.00 items from the deal?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        postCapitalizedUseTax: {
          questionKey: 'postCapitalizedUseTax',
          label: 'Post Capitalized Use Tax',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a lease deal, should VUE include capitalized use tax?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        salespersonCommissionPosting: {
          questionKey: 'salespersonCommissionPosting',
          label: 'Salesperson Commission Posting',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a deal, VUE should break down the ',
            },
            {
              attributes: { bold: true },
              text: 'salesperson commission ',
            },
            {
              attributes: null,
              text: 'by:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'employee',
              label: 'Employee',
            },
            {
              value: 'employee-and-comm-type',
              label: 'Commission type and employee',
            },
          ],
          validation: [],
          dependencies: [],
        },
        fiCommissionPosting: {
          questionKey: 'fiCommissionPosting',
          label: 'F&I Commission Posting',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a deal, VUE should break down the ',
            },
            {
              attributes: { bold: true },
              text: 'F&I manager commission ',
            },
            {
              attributes: null,
              text: 'by:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'employee',
              label: 'Employee',
            },
            {
              value: 'employee-and-comm-type',
              label: 'Commission type and employee',
            },
          ],
          validation: [],
          dependencies: [],
        },
        salesManagerCommissionPosting: {
          questionKey: 'salesManagerCommissionPosting',
          label: 'Sales Manager Commission Posting',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When posting a deal, VUE should break down the ',
            },
            {
              attributes: { bold: true },
              text: 'sales manager commission ',
            },
            {
              attributes: null,
              text: 'by:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'employee',
              label: 'Employee',
            },
            {
              value: 'employee-and-comm-type',
              label: 'Commission type and employee',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupAccountingDefaultAccountsReceivable: {
      groupLabel: 'Accounts Receivable',
      groupUrl: 'groupAccountingDefaultAccountsReceivable',
      groupType: 'standard',
      questions: {
        arInterestRate: {
          questionKey: 'arInterestRate',
          label: 'AR Interest Rate',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should automatically charge an annual interest rate of what percentage to accounts receivable customers?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default annual interest rate, and it can be edited for each accounts receivable customer.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        minimumFinanceCharge: {
          questionKey: 'minimumFinanceCharge',
          label: 'Minimum Finance Charge',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should charge a minimum finance charge of what ',
            },
            {
              attributes: { bold: true },
              text: 'dollar amount ',
            },
            {
              attributes: null,
              text: 'to accounts receivable customers?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        financeIncomeAccount: {
          questionKey: 'financeIncomeAccount',
          label: 'Finance Income Account',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should post ',
            },
            {
              attributes: { bold: true },
              text: 'finance income ',
            },
            {
              attributes: null,
              text: 'to which general ledger account?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        defaultARSchedule: {
          questionKey: 'defaultARSchedule',
          label: 'Default AR Schedule',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which ',
            },
            {
              attributes: { bold: true },
              text: 'schedule ',
            },
            {
              attributes: null,
              text: 'as your default accounts receivable schedule?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        printDuplicateReceipts: {
          questionKey: 'printDuplicateReceipts',
          label: 'Print Duplicate Receipts',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When depositing accounts receivable payments, should VUE print duplicate receipts?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        defaultARDueDate: {
          questionKey: 'defaultARDueDate',
          label: 'Default AR Due Date',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing accounts receivable statements, VUE should use which day of the month as the due date?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        printCreditCardReceipt: {
          questionKey: 'printCreditCardReceipt',
          label: 'Print Credit Card Receipt',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When accepting a credit card payment, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'never',
              label: 'Never generate a receipt.',
            },
            {
              value: 'user-defined',
              label: 'Allow the user to choose whether to generate a receipt.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        defaultARAccount: {
          questionKey: 'defaultARAccount',
          label: 'Default AR Account',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which ',
            },
            {
              attributes: { bold: true },
              text: 'general ledger account ',
            },
            {
              attributes: null,
              text: 'as your default accounts receivable account?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupAccountingDefaultAccountsPayable: {
      groupLabel: 'Accounts Payable',
      groupUrl: 'groupAccountingDefaultAccountsPayable',
      groupType: 'standard',
      questions: {
        defaultAPAccount: {
          questionKey: 'defaultAPAccount',
          label: 'Default AP Account',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which ',
            },
            {
              attributes: { bold: true },
              text: 'general ledger account ',
            },
            {
              attributes: null,
              text: 'as your default accounts payable account?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['accounting-default'];

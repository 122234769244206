import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-lock-unlocked',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    class="{{ fillColor }} {{ strokeColor }} {{ strokeWidth }}"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .i-lock-unlocked {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <title>Unlocked</title>
    <path class="i-lock-unlocked" d="M.75,9.75V6a5.25,5.25,0,0,1,10.5,0V9.75" />
    <rect
      class="i-lock-unlocked"
      x="6.75"
      y="9.75"
      width="16.5"
      height="13.5"
      rx="1.5"
      ry="1.5"
    />
    <line class="i-lock-unlocked" x1="15" y1="15" x2="15" y2="18" />
  </svg>`,
})
export class IconLockUnlockedComponent extends IconBase {}

<section class="flex flex-col gap-3">
  @for (question of questions; track question.key) {
    <article
      [id]="question.key"
      [attr.aria-selected]="
        (!expandedQuestionKey && question.key === selectedQuestionKey) ||
        question.key === expandedQuestionKey
      "
      class="ring-dms-green/50 aria-selected:border-dms-green flex w-full scroll-mt-12 flex-col rounded-md border bg-white p-5 ring-offset-2 target:ring"
    >
      <div class="flex flex-wrap-reverse items-center justify-between gap-0.5">
        <p
          [title]="question.label"
          class="truncate text-xs font-medium text-gray-500"
        >
          {{ question.label }}
        </p>

        <p
          [title]="question.key"
          class="font-mono text-[11px] font-medium text-gray-500"
        >
          {{ question.key }}
        </p>
      </div>

      @if (question.prompt) {
        <dominion-prompt
          [prompt]="question.prompt"
          class="mt-2 max-w-prose whitespace-pre-line text-[15px] font-semibold"
        ></dominion-prompt>
      }

      @if (
        question.subprompt.length > 0 &&
        question.componentType !== 'select-list'
      ) {
        <dominion-prompt
          [prompt]="question.subprompt"
          [subprompt]="true"
          class="my-2 max-w-prose whitespace-pre-line text-sm text-gray-700"
        ></dominion-prompt>
      }

      @if (question.componentType === 'select-list') {
        <ol class="my-2 ml-6 list-decimal text-[15px]">
          @for (option of question.options; track option.value) {
            <li>{{ option.label }}</li>
          }
        </ol>
      }

      @if (
        question.subprompt.length > 0 &&
        question.componentType === 'select-list'
      ) {
        <dominion-prompt
          [prompt]="question.subprompt"
          [subprompt]="true"
          class="mt-2 max-w-prose whitespace-pre-line text-sm text-gray-700"
        ></dominion-prompt>
      }

      <div class="mt-3 flex shrink-0 flex-wrap items-center gap-3">
        <div class="flex items-center gap-1.5 text-xs capitalize text-gray-500">
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="h-3 w-3 shrink-0"
          >
            <path
              d="M15.536 11.293a1 1 0 0 0 0 1.414l2.376 2.377a1 1 0 0 0 1.414 0l2.377-2.377a1 1 0 0 0 0-1.414l-2.377-2.377a1 1 0 0 0-1.414 0z"
            />
            <path
              d="M2.297 11.293a1 1 0 0 0 0 1.414l2.377 2.377a1 1 0 0 0 1.414 0l2.377-2.377a1 1 0 0 0 0-1.414L6.088 8.916a1 1 0 0 0-1.414 0z"
            />
            <path
              d="M8.916 17.912a1 1 0 0 0 0 1.415l2.377 2.376a1 1 0 0 0 1.414 0l2.377-2.376a1 1 0 0 0 0-1.415l-2.377-2.376a1 1 0 0 0-1.414 0z"
            />
            <path
              d="M8.916 4.674a1 1 0 0 0 0 1.414l2.377 2.376a1 1 0 0 0 1.414 0l2.377-2.376a1 1 0 0 0 0-1.414l-2.377-2.377a1 1 0 0 0-1.414 0z"
            />
          </svg>
          {{ question.componentType }}
        </div>

        @if (
          question.componentType === 'dropdown' ||
          (question.componentType === 'select' && question.options.length > 0)
        ) {
          <div class="text-dms-green flex max-w-[360px] items-center gap-1.5">
            <svg
              aria-label="hidden"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="h-3.5 w-3.5 shrink-0"
            >
              <rect width="18" height="14" x="3" y="5" rx="2" ry="2" />
              <path d="M7 15h4M15 15h2M7 11h2M13 11h4" />
            </svg>
            @if (question.componentType === 'dropdown') {
              <label
                class="relative flex items-center overflow-x-auto rounded-full text-xs"
              >
                <select
                  class="h-full w-full appearance-none bg-transparent pr-1.5 focus:outline-none"
                >
                  <option>Options</option>
                  @for (option of question.options; track option.value) {
                    <option disabled>{{ option.label }}</option>
                  }
                </select>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="pointer-events-none absolute right-1 h-3.5 w-3.5 shrink-0 bg-transparent"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                  />
                </svg>
              </label>
            }

            @if (
              question.componentType === 'select' && question.options.length > 0
            ) {
              <button
                (click)="expandQuestion(question.key, isDependency)"
                class="flex shrink-0 items-center text-xs"
                [disabled]="isDependency || !question.dependencies.length"
              >
                @for (option of question.options; track option.value) {
                  <div>
                    <span class="max-w-[100px] truncate">{{
                      option.label
                    }}</span>
                    @if (
                      countOptionDependencies(
                        option.value,
                        question.dependencies
                      ) > 0
                    ) {
                      <span class="text-[11px] tabular-nums"
                        >&nbsp;({{
                          countOptionDependencies(
                            option.value,
                            question.dependencies
                          )
                        }})</span
                      >
                    }
                  </div>
                  @if (!$last) {
                    <span class="px-1.5 text-gray-300">|</span>
                  }
                }
              </button>
            }
          </div>
        }

        <div class="flex-1"></div>

        <div class="flex grow flex-wrap items-center justify-end gap-1.5">
          @if (
            !isDependency && countTotalDependencies(question.dependencies) > 0
          ) {
            <button
              (click)="expandQuestion(question.key, isDependency)"
              class="border-dms-green/20 bg-dms-green/5 text-dms-green flex h-7 shrink-0 items-center gap-1.5 rounded border pl-2 pr-2.5 text-xs font-medium"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-3.5 w-3.5 shrink-0"
              >
                <path
                  d="M20 10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2.5a1 1 0 0 1-.8-.4l-.9-1.2A1 1 0 0 0 15 3h-2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z"
                />
                <path
                  d="M20 21a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-2.9a1 1 0 0 1-.88-.55l-.42-.85a1 1 0 0 0-.92-.6H13a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z"
                />
                <path d="M3 5a2 2 0 0 0 2 2h3" />
                <path d="M3 3v13a2 2 0 0 0 2 2h3" />
              </svg>
              <span>
                @if (selectedQuestion?.key === question.key) {
                  Hide
                } @else {
                  Show
                }
                dependencies
              </span>
            </button>
          }

          <button
            (click)="copyQuestionLink($event, question.key)"
            [attr.aria-selected]="
              question.key === selectedQuestionKey ||
              question.key === expandedQuestionKey
            "
            class="flex h-7 shrink-0 items-center justify-center gap-1.5 rounded border bg-gray-50 pl-2 pr-2.5 text-xs font-medium text-gray-600"
          >
            @if (copiedQuestionKey === question.key) {
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-3.5 w-3.5 shrink-0"
              >
                <path d="M20 6 9 17l-5-5" />
              </svg>
              <span>Copied link</span>
            } @else {
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-3.5 w-3.5 shrink-0"
              >
                <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
                <path
                  d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"
                />
              </svg>
              <span>Copy link</span>
            }
          </button>
        </div>
      </div>
    </article>

    @if (
      (isDependency ||
        (selectedQuestion?.key === question.key &&
          question.requirement === 'required')) &&
      question.dependencies.length > 0
    ) {
      <div class="flex flex-col gap-3 pl-10">
        @for (dependency of question.dependencies; track dependency.value) {
          <div>
            <div
              class="text-dms-green/ border-dms-green/20 bg-dms-green/5 text-dms-green inline-flex items-center gap-1.5 rounded-full border p-0.5 pl-2 text-xs"
            >
              If parent {{ dependency.method }}
              <div
                class="bg-dms-green rounded-full px-2 py-0.5 text-xs text-white"
              >
                {{ optionLabelForValue(question, dependency.value) }}
              </div>
            </div>
          </div>

          <div class="border-dms-green/20 border-l-2 pl-3">
            <dominion-question-list
              [isDependency]="true"
              [groups]="groups"
              [questions]="dependency.questions"
              [selectedModule]="selectedModule"
              [selectedSubmodule]="selectedSubmodule"
              [selectedGroupKey]="selectedGroupKey"
              [selectedQuestionKey]="selectedQuestionKey"
              [expandedQuestionKey]="expandedQuestionKey"
            ></dominion-question-list>
          </div>
        }
      </div>
    }
  }
</section>

import { ICompany } from '../company/company.interfaces';
import { VendorAuthorization } from '../company/vendor-authorization.interfaces';
import { ICoreModule } from '../modules/core/module/core-module.interfaces';
import { IFileUploadReference } from '../uploads/upload.interfaces';
import { IAddress } from '../utility/address.interfaces';
import { IDMS } from '../utility/dms.interfaces';
import {
  IStandardVendorCompanyRef,
  IVendorCustom,
} from '../vendor/vendor.interfaces';
import { FakeDocumentCore } from './fake-doc-core';

export class FakeCompany extends FakeDocumentCore implements ICompany {
  _id: string = 'c1';
  name: string = 'Fake Company';
  address: IAddress = {
    street: '123 Shire Lane',
    city: 'Hobbiton',
    state: 'AL',
    zip: '12345',
  };
  dms: IDMS | string = 'CDK';
  preferredInstallDate: Date = new Date();
  contractExpirationDate: Date = new Date();
  hubspotId: string = 'hubspotid';
  dateCreated: Date = new Date();
  createdBy: 'hubspot' | 'user' = 'hubspot';
  parentCompany: string | null = null;
  childCompanies: string[] = [];
  modules: ICoreModule[] = [];
  goLiveDate: Date | null = null;
  users: string[] = [];
  invitedUsers: string[] = [];
  internalAssigned: {
    projectmanager: string | null;
    deploymentmanager: string | null;
    deploymenttechnician: string | null;
  } = {
    projectmanager: null,
    deploymentmanager: null,
    deploymenttechnician: null,
  };
  customVendors: IVendorCustom[] = [];
  standardVendors: IStandardVendorCompanyRef[] = [];
  vendorAuthorizationSnapshots: VendorAuthorization[] = [];
  documents: IFileUploadReference[] = [];
  constructor(initializer?: Partial<ICompany>) {
    super();
    if (initializer) {
      Object.assign(this, initializer);
    }
  }
}
